import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import http from "../../http";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { connect } from "react-redux";
import "@/assets/sass/nft-application-form.scss";
import MineLayout from "@/components/mine-layout.jsx";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import Step4 from "./components/step4";
import { useNavigate } from "react-router-dom";
import { deepCopy } from "@/utils";
import dayjs from "dayjs";
let oldData = {
  nftUrls: [],
  nftSaleType: 1,
  patentRegistrUrl: "",
  patentRegistrName: "",
  patentRegistrTechnology: "",
  patenteeBy: "",
  patentRegistrAppBy: "",
  patentRegistrAppNo: "",
  patentRegistrAppTime: "",
  patentRegistrNo: "",
  patentRegistrTime: "",
  patentRegistrNotes: "",
  createBy: "",
  collectionName: "",
  nftName: "",
  collectionNumber: "",
  collectionNotes: "",
  price: "",
  revenue: "",
  salePeriod: "",
  contractInfo: "",
  isLongSale: 0,
  saleStartTime: "",
  saleEndTime: "",
  userBusType: 1,
  companyName: "",
  companyUrl: "",
  surname: "",
  name: "",
  phonenumber: "",
  appTime: "",
  distinction: "",
  country: 3,
  passportNumber: "",
  auditStatus: 1,
  showIndex: 0,
  saleEvent: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  },
  saleEventOther: "",
  compensaType: 0,
  compensaAmountPropor: "",
  rewardCycle: "",
  periodCompensa: "",
  periodStartTime: "",
  periodEndTime: "",
};
function nft(props) {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const check = !!query.get("check");
  const navigate = useNavigate();
  let step = useState(1);
  let formData = useState(deepCopy(oldData));
  let updateForm = (call) => {
    return (ev) => {
      call && call(ev);
      formData[1]({ ...formData[0] });
    };
  };
  useEffect(() => {
    updateForm(() => {
      formData[0].country = 3;
    })();
  }, [formData[0].distinction]);
  // 获取技术分类
  let [technology, setTechnology] = useState([]);
  // 获取专利成熟度
  let [patent_maturity, set_patent_maturity] = useState([]);
  // 销售期
  let [sale_period, set_sale_period] = useState([]);
  //国家列表
  let [countrys, setCountrys] = useState([]);
  // 补偿期限
  let [period_compensa, set_period_compensa] = useState([]);
  useEffect(() => {
    http
      .get("/system/dict/data/getForm/patent_registr_technology")
      .then((res) => {
        setTechnology(
          res.map((item) => ({ label: item.dictLabel, value: item.dictValue }))
        );
      });
    http.get("/system/dict/data/getForm/patent_maturity").then((res) => {
      set_patent_maturity(
        res.map((item) => ({ label: item.dictLabel, value: item.dictValue }))
      );
    });
    http.get("/system/dict/data/getForm/sale_period").then((res) => {
      set_sale_period(
        res.map((item) => ({ label: item.dictLabel, value: item.dictValue }))
      );
    });
    http.get("/system/dict/data/getForm/period_compensa").then((res) => {
      set_period_compensa(
        res.map((item) => ({ label: item.dictLabel, value: item.dictValue }))
      );
    });
    http.get("/system/config/configKey/getCountry").then((res) => {
      setCountrys(res.country);
    });
    getDetail();
  }, [id]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if (isFirstRender && !!id) {
      setIsFirstRender(false);
      return;
    }
    http
      .get(
        `/system/config/configKey/getCollectionName?nftSaleType=${formData[0].nftSaleType}`
      )
      .then((res) => {
        updateForm(() => {
          formData[0].collectionName = res;
          if (formData[0].nftSaleType == 2) {
            formData[0].collectionNumber = 1;
          }
        })();
      });
  }, [formData[0].nftSaleType]);
  useEffect(() => {
    if (formData[0].periodStartTime && formData[0].periodCompensa) {
      updateForm(() => {
        formData[0].periodEndTime = dayjs(formData[0].periodStartTime).add(
          formData[0].periodCompensa,
          "year"
        );
      })();
    } else {
      updateForm(() => {
        formData[0].periodEndTime = "";
      })();
    }
  }, [formData[0].periodStartTime, formData[0].periodCompensa]);
  let getDetail = () => {
    if (id) {
      http.get(`/system/nftForm/${id}`).then((res) => {
        res.saleEvent = JSON.parse(res.saleEvent);
        formData[1]({ ...res, showIndex: 0 });
      });
    } else {
      formData[1](deepCopy(oldData));
      step[1](process.env.NODE_ENV === "development" ? 1 : 1);
    }
  };
  function submit(auditStatus = 1) {
    let req = formData[0].id
      ? http.put("/system/nftForm", {
        ...formData[0],
        auditStatus,
        saleEvent: JSON.stringify(formData[0].saleEvent),
      })
      : http.post("/system/nftForm", {
        ...formData[0],
        auditStatus,
        saleEvent: JSON.stringify(formData[0].saleEvent),
      });
    return req.then((res) => {
      if (!!id) {
        getDetail();
        return true;
      } else {
        navigate("/nft-application");
      }
    });
  }

  let setpMap = () => {
    let map = {
      1: (
        <Step1
          step={step}
          formData={formData[0]}
          key={id}
          updateForm={updateForm}
          check={check}
          isEdit={!!id}
          submit={submit}
          technology={technology}
          patent_maturity={patent_maturity}
        />
      ),
      2: (
        <Step2
          step={step}
          formData={formData[0]}
          key={id}
          updateForm={updateForm}
          check={check}
          isEdit={!!id}
          submit={submit}
        />
      ),
      3: (
        <Step3
          step={step}
          formData={formData[0]}
          key={id}
          updateForm={updateForm}
          check={check}
          isEdit={!!id}
          submit={submit}
          sale_period={sale_period}
          period_compensa={period_compensa}
        />
      ),
      4: (
        <Step4
          step={step}
          formData={formData[0]}
          key={id}
          updateForm={updateForm}
          check={check}
          isEdit={!!id}
          submit={submit}
          countrys={countrys}
        />
      ),
    };
    return map[step[0]];
  };

  return (
    <>
      {contextHolder}
      <MineLayout>
        <div className="cs-profile_right nft-application-form">
          <div className="left">
            <h4 style={{ color: "#2b2b2b" }}><b>{t('application.nft_application')}</b></h4>
            <div className="step">
              <div className={"item " + (step[0] > 0 ? "active" : "")}>
                <div className="box">
                  <div className="line">01</div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: t('application.patent_data_duplicate_2') }}></div>
                </div>
              </div>
              <div className={"item " + (step[0] > 1 ? "active" : "")}>
                <div className="box">
                  <div className="line">02</div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: t('application.nft_metadata') }}></div>
                </div>
              </div>
              <div className={"item " + (step[0] > 2 ? "active" : "")}>
                <div className="box">
                  <div className="line">03</div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: t('application.nft_sales_data') }}></div>
                </div>
              </div>
              <div className={"item " + (step[0] > 3 ? "active" : "")}>
                <div className="box">
                  <div className="line">04</div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: t('application.additional_personal_info') }}></div>
                </div>
              </div>
            </div>
            {setpMap()}
          </div>
          <div className="right">
            <div className="panel-title d-flex align-items-center">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.54167 14.875C3.15208 14.875 2.81858 14.7363 2.54115 14.4589C2.26372 14.1814 2.125 13.8479 2.125 13.4583V3.54167C2.125 3.15208 2.26372 2.81858 2.54115 2.54115C2.81858 2.26372 3.15208 2.125 3.54167 2.125H13.4583C13.8479 2.125 14.1814 2.26372 14.4589 2.54115C14.7363 2.81858 14.875 3.15208 14.875 3.54167V13.4583C14.875 13.8479 14.7363 14.1814 14.4589 14.4589C14.1814 14.7363 13.8479 14.875 13.4583 14.875H3.54167ZM3.54167 13.4583H13.4583V4.95833H3.54167V13.4583ZM8.5 12.0417C7.53194 12.0417 6.66719 11.779 5.90573 11.2536C5.14427 10.7283 4.59236 10.0465 4.25 9.20833C4.59236 8.37014 5.14427 7.68837 5.90573 7.16302C6.66719 6.63767 7.53194 6.375 8.5 6.375C9.46806 6.375 10.3328 6.63767 11.0943 7.16302C11.8557 7.68837 12.4076 8.37014 12.75 9.20833C12.4076 10.0465 11.8557 10.7283 11.0943 11.2536C10.3328 11.779 9.46806 12.0417 8.5 12.0417ZM8.5 10.9792C9.16111 10.9792 9.76319 10.8227 10.3062 10.5099C10.8493 10.197 11.2743 9.76319 11.5813 9.20833C11.2743 8.65347 10.8493 8.21962 10.3062 7.90677C9.76319 7.59392 9.16111 7.4375 8.5 7.4375C7.83889 7.4375 7.23681 7.59392 6.69375 7.90677C6.15069 8.21962 5.72569 8.65347 5.41875 9.20833C5.72569 9.76319 6.15069 10.197 6.69375 10.5099C7.23681 10.8227 7.83889 10.9792 8.5 10.9792ZM8.5 10.2708C8.79514 10.2708 9.04601 10.1675 9.2526 9.96094C9.4592 9.75434 9.5625 9.50347 9.5625 9.20833C9.5625 8.91319 9.4592 8.66233 9.2526 8.45573C9.04601 8.24913 8.79514 8.14583 8.5 8.14583C8.20486 8.14583 7.95399 8.24913 7.7474 8.45573C7.5408 8.66233 7.4375 8.91319 7.4375 9.20833C7.4375 9.50347 7.5408 9.75434 7.7474 9.96094C7.95399 10.1675 8.20486 10.2708 8.5 10.2708Z"
                  fill="#3772FF"
                />
              </svg>
              &nbsp; {t('application.preview')}
            </div>
            {!!formData[0].nftUrls?.length && (
              <img
                className="img"
                src={formData[0]?.nftUrls[formData[0].showIndex]}
              />
            )}
            <div className="title GmarketSans">{formData[0].nftName || "--"} </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="user d-flex align-items-center">
                <img className="avatar" src={props.userInfo.avatar} alt="" />
                <div className="name ">{props.userInfo.nickName}</div>
              </div>
              <div className="">
                {t('application.sales_price_duplicate')} &nbsp;
                <span className="num">{formData[0].price || "--"}</span>
                <span className="unit">BPT</span>
              </div>
            </div>
          </div>
        </div>
      </MineLayout>
    </>
  );
}

export default connect((state) => {
  return {
    language: state.language,
    userInfo: state.userInfo,
  };
})(nft);
