import "./App.css";
import "./assets/sass/common.scss";

// import "./App.scss";
import NavHeader from "./components/nav-header";
import Footer from "./components/Footer";
import RouterView from "./router";
import { Spin, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Provider, connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollTo from "./components/ScrollTo";
function App(props) {
  const [modal, contextModalHolder] = Modal.useModal();
  const { t } = useTranslation();
  const Location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      ) || window.innerWidth <= 768
    );
  };
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      ) || window.innerWidth <= 768
    );
    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      $(".cs-preloader").delay(150).fadeOut("slow");
    }, 5000);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.reset && window.reset();
  }, [Location]);

  useEffect(() => {
    const isDApp = () => {
      if (
        typeof window.ethereum !== "undefined" ||
        typeof window.web3 !== "undefined"
      ) {
        return true;
      } else {
        return false;
      }
    };

    if (!isDApp()) {
      confirm();
    }
  }, []);

  const onOpenDapp = () => {
    const dappUrl = "https://bpt2.macgic.top";
    const deepLink = `https://metamask.app.link/dapp/${dappUrl}`;
    window.location.href = deepLink;
  };
  let confirm = () => {
    let mint = modal.confirm({
      className: "mint-modal",
      title: null,
      width: "650px",
      content: (
        <div className="box" style={{ padding: 0 }}>
          <h3
            className="title text-center GmarketSans"
            style={{ fontSize: "30px", fontWeight: 700, color: "#2B2B2B" }}
          >
            {t("tips.title")}
          </h3>
          <div
            className="text-center mx-auto"
            style={{
              padding: "10px",
              maxWidth: "560px",
              fontSize: "16px",
              marginBottom: "25px",
              lineHeight: "30px",
              marginTop: "40px",
              fontWeight: 300,
              background: "#F6F6F6",
              fontWeight: 500,
              color: "#2B2B2B",
              borderRadius: "20px",
            }}
          >
            {t("tips.content")}
          </div>
        </div>
      ),
      footer: (
        <div
          className="d-flex gap-3 footer-btn-group mx-auto"
          style={{ maxWidth: "100%" }}
        >
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 hollow"
            onClick={() => {
              mint.destroy();
            }}
          >
            <span>{t("application.cancel")}</span>
          </button>
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 w-100"
            onClick={() => {
              mint.destroy();
              onOpenDapp();
            }}
          >
            <span> {t("tips.open")}</span>
          </button>
        </div>
      ),
    });
  };
  return (
    <>
      {contextModalHolder}
      <ScrollTo />
      <div className="cs-preloader cs-center">
        <div className="cs-preloader_in"></div>
        <span>Loading</span>
      </div>
      {/* {isMobile && (
        <div className="home-dialogs">
          <div className="dialog-contents">
            <img src="/assets/img/waining-bg.png" alt="" />
            <div className="dialog-title">Notification</div>
            <br/>
            <div className="dialog-desc">
            현재 해당 서비스는 모바일에서 이용이 어려우며, <br/>
            개발 중에 있습니다. 번거로우시겠지만, <br/>
            PC를 통해 방문해 주시기 바랍니다.
            </div>
            <br/>
            <div className="dialog-desc">
            'Best Patent' service is currently not available on mobile devices as it is still under development. We recommend accessing it via PC for now. We apologize for the inconvenience.
            </div>
            <a className="cs-btn cs-style1">
              <span>닫기</span>
            </a>
          </div>
        </div>
      )} */}
      <div id={Location.pathname}></div>
      <Spin
        style={{ position: "fixed" }}
        spinning={props.isLoading}
        indicator={<LoadingOutlined spin />}
        tip="loading..."
        size="large"
      >
        <div className="App" id="app">
          <NavHeader />
          <RouterView key={Location.pathname} />
          <Footer />
        </div>
      </Spin>
    </>
  );
}

export default connect((state) => {
  return {
    isLoading: state.isLoading,
  };
})(App);
