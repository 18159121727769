import { useTranslation } from "react-i18next";
export default ({ onClick }) => {
  const { t } = useTranslation();
  return <div className="d-flex align-items-center  pointer me-auto GmarketSans" onClick={onClick}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7082 15.3958L6.2915 9.97917L11.7082 4.5625L13.2498 6.10417L9.37484 9.97917L13.2498 13.8542L11.7082 15.3958Z"
        fill="#494F6A"
      />
    </svg>
    &nbsp; {t('application.prev')}
  </div>
}
