import { useTranslation } from "react-i18next";
import MineLayout from "@/components/mine-layout.jsx";
import { message, Modal, Empty } from "antd";
export default function UserItems() {
  const { t } = useTranslation();
  return (
    <>
      <MineLayout>
        <div className="cs-profile_right">
          <div className="cs-height_30 cs-height_lg_30"></div>
          <h2 className="cs-section_heading cs-style1 mb-4">
            {t('userItem.nft_sales')}
          </h2>
          <Empty description="" />
        </div>
      </MineLayout>
    </>
  );
}
