import { useTranslation } from "react-i18next";
export default ({ onClick }) => {
  const { t } = useTranslation();
  return <div className="d-flex align-items-center pointer ms-auto GmarketSans" onClick={onClick}>
    {t('application.next')}
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1667 9.97917L6.29175 6.10417L7.83341 4.5625L13.2501 9.97917L7.83341 15.3958L6.29175 13.8542L10.1667 9.97917Z"
        fill="#494F6A"
      />
    </svg>
  </div>
}
