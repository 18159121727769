
export function deepCopy(obj) {
	if (typeof obj !== 'object' || obj === null) {
		return obj;
	}
	let copy = Array.isArray(obj) ? [] : {};
	for (let i in obj) {
		if (obj.hasOwnProperty(i)) {
			if (typeof obj[i] === 'object') {
				copy[i] = deepCopy(obj[i]);
			} else {
				copy[i] = obj[i];
			}
		}
	}
	return copy;
}