import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.reset && window.reset();

    setTimeout(() => {
      const appElement = document.getElementById("app");
      if (appElement) {
        appElement.scrollTop = 0;
      }
    }, 100);
  }, [location]);

  return null;
};

export default ScrollToTop;
