import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import http from "../http";
import { getTokenURI } from "../utils/ethNft.js";
import { message, Checkbox, Radio, Space } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import GeneralKo from "../agreement/general-ko";
import GeneralEn from "../agreement/general-en";
import { connect } from "react-redux";
import BInput from "../components/b-input.jsx";
import BSelect from "../components/b-select.jsx";
function Explore1(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const title = query.get("title");
  let [data, setData] = useState([]);
  let [tab, setTabs] = useState([]);
  let [ids, setIds] = useState("");
  const [type, setType] = useState("");
  let [child_id_str, setchild_id_str] = useState("");
  let [collapses, setCollapses] = useState([]);

  useEffect(() => {
    http.getnft(`/bpt-nft/nft_type_list?p_id=2`).then((res) => {
      const data = [];
      setchild_id_str(res.child_id_str);
      setType(res.child_id_str);
      res.list.forEach((_val) => {
        let obj = {
          label: props.language == "en" ? _val.name_en : _val.name_kr,
          value: _val.id,
          show: true,
          list: [],
          child_id_str: _val.child_id_str,
        };
        if (_val.id == 16) {
          obj.label = (
            <>
              INVERTER &nbsp;
              <svg
                className="pointer"
                data-modal="#inverter_pop"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.23151 14.5315C2.74562 14.5315 2.32973 14.3585 1.98384 14.0125C1.63784 13.6666 1.46484 13.2507 1.46484 12.7648V3.23151C1.46484 2.74573 1.63784 2.32984 1.98384 1.98384C2.32973 1.63784 2.74562 1.46484 3.23151 1.46484H8.31484V3.23151H3.23151V12.7648H12.7648V7.68151H14.5315V12.7648C14.5315 13.2507 14.3585 13.6666 14.0125 14.0125C13.6665 14.3585 13.2506 14.5315 12.7648 14.5315H3.23151ZM6.41484 10.8148L5.18151 9.58151L11.5315 3.23151H9.64818V1.46484H14.5315V6.34818H12.7648V4.46484L6.41484 10.8148Z"
                  fill="#494F6A"
                />
              </svg>
            </>
          );
        }
        _val.childCategories.forEach((_val2) => {
          let obj2 = {
            label: props.language == "en" ? _val2.name_en : _val2.name_kr,
            value: _val2.id,
            active: false,
          };

          obj.list.push(obj2);
        });
        data.push(obj);
      });
      setCollapses(data);
      getList(res.child_id_str);
    });
  }, []);
  const getList = (ids, type) => {
    setIds(ids);
    // setData([]);
    let url = `/bpt-nft/nft_list?page=0&take=1000&type_id=${
      ids || child_id_str
    }&order=${type || ""}`;

    http.getnft(url).then(async (res) => {
      let data = await Promise.all(
        res.list.map(async (_item) => {
          const _nft = await http
            .getnft(
              `/bpt-nft/nft_info?token_id=${_item.token_id}&address=${_item.contract_address}`
            )
            .then((res) => res.info.nfi_info);

          return { ..._item, ..._nft };
        })
      );
      setData(data);
    });
  };
  // useEffect(() => {
  //   http
  //     .getnft(
  //       `/bpt-nft/nft_list?take=100&type_id=2`
  //       //  `/bpt-nft/nft_list?address=${process.env.REACT_APP_GENERAL}&take=100`
  //     )
  //     .then(async (_result) => {
  //       try {
  //         // 使用 Promise.all 来等待所有异步操作完成
  //         const data = await Promise.all(
  //           window._.map(_result.list, async (_item) => {
  //             // 获取 token URI
  //             const _nft = await http
  //               .getnft(
  //                 `/bpt-nft/nft_info?token_id=${_item.token_id}&address=${_item.contract_address}`
  //               )
  //               .then((res) => {
  //                 return res.info.nfi_info;
  //               });
  //             return {
  //               ..._item,
  //               ..._nft,
  //             };
  //           })
  //         );

  //         // 现在 data 是解析后的结果数组
  //         setData(data);
  //         console.log(data);
  //       } catch (error) {
  //         console.error("Error fetching NFTs:", error);
  //       }
  //     })
  //     .catch(() => {});
  // }, []);
  let toDetail = (item) => {
    if (item.is_lock)
      return message.info(t("general.locked", { name: item.name }));
    localStorage.setItem("detail_" + item.id, JSON.stringify(item));
    navigate("/explore-details?id=" + item.id);
  };
  let [checked1, setChecked1] = useState(false);
  let [checked2, setChecked2] = useState(false);
  let [select1, setSelect1] = useState("");
  let [select2, setSelect2] = useState("");
  let [select3, setSelect3] = useState("");
  let [select4, setSelect4] = useState("");

  const selectAll = (list) => {
    setTimeout(() => {
      getList(list.active == true ? list.child_id_str : "");
    }, 300);
  };

  const selectCol = (list) => {
    list.active = !list.active;
    const result = _.flatMap(collapses, (item) =>
      _.filter(item.list, { active: true }).map((i) => i.value)
    );
    getList(result.length ? result.join() : "");
  };
  let updateColl = (call) => {
    return () => {
      call && call();
      setCollapses([...collapses]);
    };
  };

  let isCheckedAll = (item) => {
    if (item.list.length) return item.list.every((item) => item.active);
    return item.active;
  };
  let checkedAll = (item) => {
    updateColl(() => {
      let boo = isCheckedAll(item);
      if (item.list.length) {
        item.list.forEach((item) => (item.active = !boo));
      } else {
        item.active = !item.active;
      }
    })();
  };

  return (
    <>
      {/* End Header Section */}

      <div className="cs-height_90 cs-height_lg_80"></div>
      {/* Start Page Head */}
      <section className="cs-page_head cs-bg" data-src="/assets/img/Patent.jpg">
        <div className="container" style={{ color: "#fff" }}>
          <div className="top-nav-tip">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.52923 10.0833H3.91465V6.39376H8.08548V10.0833H9.4709V4.86251L6.00007 2.26667L2.52923 4.86251V10.0833ZM0.983398 11.6292V4.08959L6.00007 0.341675L11.0167 4.08959V11.6292H6.53965V7.93959H5.46048V11.6292H0.983398Z"
                fill="white"
              />
            </svg>
            {t("components.home")}
            <span>
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.625 7.48438L4.71875 4.57812L5.875 3.42188L9.9375 7.48438L5.875 11.5469L4.71875 10.3906L7.625 7.48438Z"
                  fill="white"
                />
              </svg>
              {t("general.patent_nft")}
            </span>
          </div>
          <div className="text-center">
            <h1 className="cs-page_title" style={{ color: "#fff" }}>
              {t("general.patent_nft")}
            </h1>
            <img src="/assets/img/line.png" alt="Avatar" />
            <p
              dangerouslySetInnerHTML={{ __html: t("general.description") }}
            ></p>
          </div>
        </div>
      </section>
      {/* End Page Head */}
      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container">
        <div className="cs-sidebar_frame cs-style1">
          <div className="cs-sidebar_frame_left">
            <BInput
              className="sm"
              placeholder={t("general.search")}
              after={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5208 18.0404L11.2292 12.7487C10.8125 13.0681 10.3021 13.3216 9.69792 13.5091C9.09375 13.6966 8.47917 13.7904 7.85417 13.7904C6.20139 13.7904 4.80208 13.2174 3.65625 12.0716C2.51042 10.9258 1.9375 9.52648 1.9375 7.8737C1.9375 6.22092 2.51042 4.82161 3.65625 3.67578C4.80208 2.52995 6.20139 1.95703 7.85417 1.95703C9.50694 1.95703 10.9062 2.52995 12.0521 3.67578C13.1979 4.82161 13.7708 6.22092 13.7708 7.8737C13.7708 8.4987 13.684 9.09592 13.5104 9.66536C13.3368 10.2348 13.0833 10.7418 12.75 11.1862L18.0625 16.4987L16.5208 18.0404ZM7.85417 11.582C8.89583 11.582 9.77431 11.2244 10.4896 10.5091C11.2049 9.79384 11.5625 8.91536 11.5625 7.8737C11.5625 6.83203 11.2049 5.95356 10.4896 5.23828C9.77431 4.523 8.89583 4.16536 7.85417 4.16536C6.8125 4.16536 5.93403 4.523 5.21875 5.23828C4.50347 5.95356 4.14583 6.83203 4.14583 7.8737C4.14583 8.91536 4.50347 9.79384 5.21875 10.5091C5.93403 11.2244 6.8125 11.582 7.85417 11.582Z"
                    fill="#494F6A"
                  />
                </svg>
              }
            />
            <div className="panel">
              <div className="title">{t("general.category")}</div>
              <div className="collapse-list">
                {collapses.map((item) => (
                  <div
                    className={"item " + (item.show ? "active" : "")}
                    key={item.label}
                  >
                    <div
                      className="label"
                      onClick={updateColl(() => selectAll(item))}
                    >
                      <Checkbox
                        checked={isCheckedAll(item)}
                        onChange={() => checkedAll(item)}
                      >
                        {item.label}
                      </Checkbox>
                      <svg
                        onClick={updateColl(() => (item.show = !item.show))}
                        className="icon"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.0151 10.5648L3.64844 6.19818L4.88177 4.96484L8.0151 8.09818L11.1484 4.96484L12.3818 6.19818L8.0151 10.5648Z"
                          fill="#494F6A"
                        />
                      </svg>
                    </div>
                    <div className="content">
                      <Space direction="vertical">
                        {item.list.map((minItem) => (
                          <Radio
                            checked={minItem.active}
                            onClick={updateColl(() => selectCol(minItem))}
                            key={minItem.value}
                          >
                            {minItem.label}
                          </Radio>
                        ))}
                      </Space>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="d-flex justify-content-between align-items-center mt-2">
                <Radio
                  checked={checked1}
                  onClick={() => setChecked1(!checked1)}
                >
                  INVERTER
                </Radio>
                <svg
                  className="pointer"
                  data-modal="#inverter_pop"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.23151 14.5315C2.74562 14.5315 2.32973 14.3585 1.98384 14.0125C1.63784 13.6666 1.46484 13.2507 1.46484 12.7648V3.23151C1.46484 2.74573 1.63784 2.32984 1.98384 1.98384C2.32973 1.63784 2.74562 1.46484 3.23151 1.46484H8.31484V3.23151H3.23151V12.7648H12.7648V7.68151H14.5315V12.7648C14.5315 13.2507 14.3585 13.6666 14.0125 14.0125C13.6665 14.3585 13.2506 14.5315 12.7648 14.5315H3.23151ZM6.41484 10.8148L5.18151 9.58151L11.5315 3.23151H9.64818V1.46484H14.5315V6.34818H12.7648V4.46484L6.41484 10.8148Z"
                    fill="#494F6A"
                  />
                </svg>
              </div> */}
            </div>
            <div className="panel">
              <div className="title">{t("general.price")}</div>
              <BSelect
                className="sm mt-2"
                list={[{ label: "BPT", value: 1 }]}
                placeholder="Price"
                value={select1}
                onChange={(val) => setSelect1(val)}
              />
            </div>
            {/* <div className="panel">
              <div className="title">{t('general.collection')}</div>
              <div className="d-flex justify-content-between align-items-center mt-2">
                <Radio checked={checked2} onClick={() => setChecked2(!checked2)}>
                  INVERTER
                </Radio>
              </div>
            </div> */}
          </div>
          <div className="cs-sidebar_frame_right">
            <div className="d-flex">
              <div className="cs-filter_head_left flex-1"></div>
              <div className="cs-filter_head_right">
                <BSelect
                  className="blue-box sm position-relative z-1 w-180"
                  placeholder={t("general.sorting_criteria")}
                  list={[
                    { label: t("general.low_price"), value: 1 },
                    { label: t("general.h_t_l"), value: 2 },
                    { label: t("general.new_first"), value: 3 },
                    { label: t("general.most_popular2"), value: 4 },
                  ]}
                  value={select3}
                  onChange={(val) => setSelect3(val)}
                />

                <BSelect
                  className="blue-box sm position-relative z-1 w-180"
                  placeholder={t("general.nft_type")}
                  list={[
                    { label: t("general.All"), value: 1 },
                    { label: t("general.s_nft"), value: 2 },
                    { label: t("general.p_nft"), value: 3 },
                  ]}
                  value={select4}
                  onChange={(val) => setSelect4(val)}
                />
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
            <div className="row">
              {data.map((item) => (
                <div className="col-xl-3 col-lg-4 col-sm-6" key={item.id}>
                  <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                    {/* <span className="cs-card_like cs-primary_color">
                      <i className="fas fa-heart fa-fw"></i>
                      2.1K
                    </span> */}
                    <a
                      onClick={() => toDetail(item)}
                      className="cs-card_thumb cs-zoom_effect"
                    >
                      <img
                        src={item.image}
                        alt="Image"
                        className="cs-zoom_item"
                      />
                    </a>
                    <div className="cs-card_info" style={{ marginTop: 0 }}>
                      {/* <a href="#" className="cs-avatar cs-white_bg">
                      <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                      <span>Johny E.</span>
                    </a> */}
                      <h3 className="cs-card_title">
                        <a onClick={() => toDetail(item)}>{item.name}</a>
                      </h3>
                      <div className="cs-card_price">
                        {t("general.price")}:{" "}
                        <b className="cs-primary_color">
                          {item.pay_token_number} BPT
                        </b>
                      </div>
                      <hr />
                      <div className="cs-card_footer">
                        <span className="cs-card_btn_1" data-modal="#history_1">
                          {/* <i className="fas fa-redo fa-fw"></i>
                      View History */}
                        </span>
                        <a
                          onClick={() => toDetail(item)}
                          className={
                            item.is_lock ? "cs-card_btn_1" : "cs-card_btn_2"
                          }
                          data-params={JSON.stringify(item)}
                        >
                          <span>
                            {item.is_lock
                              ? t("components.lock")
                              : t("components.purchase")}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="cs-height_30 cs-height_lg_30"></div>
                </div>
              ))}
            </div>
            <div className="cs-height_10 cs-height_lg_10"></div>
            {/* <div className="text-center">
              <a href="#" className="cs-btn cs-style1 cs-btn_lg">
                <span>더 보기</span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="cs-modal_wrap" id="inverter_pop">
        <div className="cs-modal_overlay"></div>
        <div className="cs-modal_container">
          <div className="cs-modal_container_in">
            <div className="cs-modal_close cs-center">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9649 2.54988C12.3554 2.15936 12.3554 1.52619 11.9649 1.13567C11.5744 0.745142 10.9412 0.745142 10.5507 1.13567L11.9649 2.54988ZM0.550706 11.1357C0.160181 11.5262 0.160181 12.1594 0.550706 12.5499C0.94123 12.9404 1.5744 12.9404 1.96492 12.5499L0.550706 11.1357ZM1.96492 1.13567C1.5744 0.745142 0.94123 0.745142 0.550706 1.13567C0.160181 1.52619 0.160181 2.15936 0.550706 2.54988L1.96492 1.13567ZM10.5507 12.5499C10.9412 12.9404 11.5744 12.9404 11.9649 12.5499C12.3554 12.1594 12.3554 11.5262 11.9649 11.1357L10.5507 12.5499ZM10.5507 1.13567L0.550706 11.1357L1.96492 12.5499L11.9649 2.54988L10.5507 1.13567ZM0.550706 2.54988L10.5507 12.5499L11.9649 11.1357L1.96492 1.13567L0.550706 2.54988Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="cs-bid_card1">
              {props.language == "ko" && <GeneralKo />}
              {props.language == "en" && <GeneralEn />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default connect((state) => {
  return {
    language: state.language,
  };
})(Explore1);
