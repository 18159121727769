import { useWallet } from "../WalletContext";
import { useEffect, useState } from "react";
import { getTokenURI } from "../utils/ethNft.js";
import axios from "axios";
import http from "../http";
import store from "@/store";
import Web3 from "web3";
import { message, Modal, Empty } from "antd";
import contractABIstr from "../utils/contractABI.json";
import { useTranslation } from "react-i18next";
import MineLayout from "@/components/mine-layout.jsx";
export default function UserItems() {
  const [modal, contextModalHolder] = Modal.useModal();
  const { t } = useTranslation();
  const { wallet } = useWallet();
  let [data, setData] = useState([]);

  useEffect(() => {

    if (!wallet?.account) return;
    http
      .get(`/system/krNft/list?walletAddress=${wallet?.account}`)
      .then(async (_result) => {
        console.log("_result===", _result);
        const data = await Promise.all(
          window._.map(_result.rows, async (_item) => {
            console.log("_item==", _item, "==");
            const _nft = await http
              .getnft(
                `/bpt-nft/nft_info?token_id=${_item.tokenId}&address=${_item.contractAddress}`
              )
              .then((res) => {
                return res.info.nfi_info;
              });
            return {
              ..._item,
              ..._nft,
            };
          })
        );
        setData(data);
      });
  }, wallet?.account);
  let confirm = () => {
    let mint = modal.confirm({
      className: "mint-modal",
      title: null,
      width: "652px",
      content: (
        <div className="box">
          <div
            className="mx-auto d-flex gap-3 align-items-center"
            style={{
              padding: "28px 20px 28px 20px",
              borderRadius: "20px",
              background: "#F6F6F6",
              fontSize: "20px",
              color: "#2b2b2b",
              fontWeight: "500",
              letterSpacing: "-0.08em",
              marginBottom: "9px"
            }}
          >
            <div>
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.0013 23.833V20.1663H40.3346V23.833H33.0013ZM35.2013 36.6663L29.3346 32.2663L31.5346 29.333L37.4013 33.733L35.2013 36.6663ZM31.5346 14.6663L29.3346 11.733L35.2013 7.33301L37.4013 10.2663L31.5346 14.6663ZM9.16797 34.833V27.4997H7.33464C6.3263 27.4997 5.46311 27.1406 4.74505 26.4226C4.027 25.7045 3.66797 24.8413 3.66797 23.833V20.1663C3.66797 19.158 4.027 18.2948 4.74505 17.5768C5.46311 16.8587 6.3263 16.4997 7.33464 16.4997H14.668L23.8346 10.9997V32.9997L14.668 27.4997H12.8346V34.833H9.16797ZM20.168 26.4913V17.508L15.6763 20.1663H7.33464V23.833H15.6763L20.168 26.4913ZM25.668 28.1413V15.858C26.493 16.5913 27.1576 17.4851 27.6617 18.5393C28.1659 19.5934 28.418 20.7469 28.418 21.9997C28.418 23.2525 28.1659 24.4059 27.6617 25.4601C27.1576 26.5143 26.493 27.408 25.668 28.1413Z" fill="#3772FF" />
              </svg>
            </div>
            <div dangerouslySetInnerHTML={{ __html: t('userNfts.tips') }}>
            </div>
          </div>
        </div>
      ),
      footer: (
        <div
          className="d-flex gap-3 footer-btn-group mx-auto"
          style={{ maxWidth: "100%" }}
        >
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 w-100"
            onClick={() => {
              mint.destroy();
            }}
          >
            <span>{t('application.confirm')}</span>
          </button>
        </div>
      ),
    });
  };
  let toDetail = (item) => {
    confirm();
  }
  return (
    <>
      {contextModalHolder}
      <MineLayout>
        <div className="cs-profile_right">
          <div className="cs-height_30 cs-height_lg_30"></div>
          <h2 className="cs-section_heading cs-style1">
            {t("userItems.holdingsRecord")}
          </h2>
          <div className="cs-height_25 cs-height_lg_25"></div>
          <div className="row">
            {data.map((item) => (
              <div className="col-xl-4 col-lg-4 col-sm-6" key={item.id}>
                <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                  {/* <span className="cs-card_like cs-primary_color">
                      <i className="fas fa-heart fa-fw"></i>
                      2.1K
                    </span> */}
                  <a
                    onClick={() => toDetail(item)}
                    className="cs-card_thumb cs-zoom_effect"
                  >
                    <img
                      src={item.image}
                      alt="Image"
                      className="cs-zoom_item"
                    />
                  </a>
                  <div className="cs-card_info" style={{ marginTop: 0 }}>
                    <div className="d-flex">
                      <div className="flex-1">
                        <a href="#" className="cs-avatar cs-white_bg">
                          <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                          <span>Johny E.</span>
                        </a>
                        <h3 className="cs-card_title">
                          <a style={{ color: "#494F6A", fontSize: "16px", opacity: .8 }}>{item.name}</a>
                        </h3>
                      </div>
                      <div>
                        {item.typeId == 0 && <button className="tip-btn" style={{
                          marginTop: "15px"
                        }}>{t('userItems.trde')}</button>}
                        {item.typeId == 1 && <button className="tip-btn" style={{
                          marginTop: "15px"
                        }}>{t('userItems.sponsorship')}</button>}

                      </div>
                    </div>

                    <div className="cs-card_price" style={{ textAlign: "start" }}>
                      {t("bigtech.price")}:{" "}
                      <b className="cs-primary_color">
                        {item.pay_token_number} <span className="bg-text2">BPT</span>
                      </b>
                    </div>
                    <hr />
                    <div className="cs-card_footer gap-1">
                      <span className="cs-card_btn_1 flex-1" data-modal="#history_1">
                        {/* <i className="fas fa-redo fa-fw"></i>
                      View History */}
                      </span>
                      {/* 按钮 */}
                      {/* <button className="btn-primary">판매</button>
                      <button className="btn-disabled">판매 중</button>
                      <button className="btn-danger">취소</button> */}
                      <a
                        onClick={() => toDetail(item)}
                        className={
                          item.is_lock ? "cs-card_btn_1" : "cs-card_btn_2"
                        }
                        data-params={JSON.stringify(item)}
                      >
                        <span>
                          {item.is_lock
                            ? t("components.lock")
                            : t('components.sale')}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="cs-height_30 cs-height_lg_30"></div>
              </div>
            ))}
            {!data.length && <Empty description="" />}
          </div>
          {/* <div className="row">
            {data.map((item) => (
              <div
                className="col-xl-3 col-lg-4 col-sm-6"
                onClick={() => onOpensea(item)}
              >
                <div className="cs-card cs-style4 cs-box_shadow cs-white_bg  position-relative">
                  <a
                    href="javascript:void(0);"
                    className="cs-card_thumb cs-zoom_effect"
                  >
                    <img
                      src={item.image}
                      alt="Image"
                      className="cs-zoom_item"
                    />
                  </a>
                  <div className="cs-card_info" style={{ marginTop: 0 }}>
                    <h3 className="cs-card_title">
                      <a href="#/explore-details">{item.name}</a>
                    </h3>
                  </div>
                </div>
                <div className="cs-height_30 cs-height_lg_30"></div>
              </div>
            ))}
          </div> */}
          {/* <div className="cs-height_10 cs-height_lg_10"></div> */}
          {/* <div className="text-center">
              <a href="#" className="cs-btn cs-style1 cs-btn_lg">
                <span>Load More</span>
              </a>
            </div> */}
        </div>
      </MineLayout>
    </>
  );
}
