import BInput from "@/components/b-input.jsx";
import BSelect from "@/components/b-select.jsx";
import http from "@/http";
import { useEffect, useState } from "react";
import { message } from "antd";
import Prev from "./prev";
import Next from "./next";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default ({
  step,
  formData,
  updateForm,
  submit,
  countrys,
  isEdit,
  check,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const checkFile = () => {
    if (disabled) return;
    $('<input type="file" accept=".jpg,.png,.pdf"/>')
      .change(function (event) {
        let form = new FormData();
        form.append("file", this.files[0]);
        http
          .post("/common/upload", form, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            updateForm(() => {
              formData.companyUrl = res.url;
            })();
          });
      })
      .trigger("click");
  };
  let [disabled, setDisabled] = useState(check);
  const toNext = () => {
    // 检查必填字段
    if (formData.userBusType == null) {
      return messageApi.error(t('application.select_nft_sales_type')); // 提示用户选择NFT销售类型
    }
    if (formData.userBusType === 1) {
      if (!formData.companyName) {
        return messageApi.error(t('application.enter_patent_name')); // 提示用户输入特许名称
      }
      if (!formData.companyUrl) {
        return messageApi.error(t('application.upload_patent_certificate_duplicate')); // 提示用户上传专利注册证
      }
    }
    if (!formData.surname) {
      return messageApi.error(t('application.enter_last_name')); // 提示用户输入姓（法人）
    }
    if (!formData.name) {
      return messageApi.error(t('application.enter_first_name')); // 提示用户输入名（法人）
    }
    if (!formData.phonenumber) {
      return messageApi.error(t('application.enter_phone_number')); // 提示用户输入电话号码
    }
    // if (!formData.distinction) {
    //   return messageApi.error("구분을 선택해 주세요."); // 提示用户选择区分
    // }
    // if (!formData.country) {
    //   return messageApi.error("국가를 선택해 주세요."); // 提示用户选择国家
    // }
    // if (!formData.appTime) {
    //   return messageApi.error("신청일을 입력해 주세요."); // 提示用户输入申请日
    // }
    // 如果所有必填项都已填写，则调用下一步
    step[1](4);
  };
  function isPdfLink(url) {
    // 使用正则表达式检查链接是否以 .pdf 结尾
    const pdfPattern = /\.pdf$/i;
    return pdfPattern.test(url);
  }
  return (
    <div className={disabled ? "page-disabled" : ""}>
      {contextHolder}
      <h4 className="h-title">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {t('application.additional_personal_info_duplicate')}
          </div>
          <div style={{ fontSize: "12px", color: "#96A0CB" }}>
            <b className="cs-form-item_must">*</b>
            {t('application.it_is_necessary')}
          </div>
        </div>
      </h4>
      <div className="item">
        <div className="label">
          {t('application.user_type')}<b className="cs-form-item_must">*</b>
        </div>
        <div className="d-flex tab-nav">
          <div
            className={
              "tab-item " + (formData.userBusType == 1 ? "active" : "")
            }
            onClick={updateForm(() => (formData.userBusType = 1))}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 21.5V5.16667H5.16667V0.5H16.8333V9.83333H21.5V21.5H12.1667V16.8333H9.83333V21.5H0.5ZM2.83333 19.1667H5.16667V16.8333H2.83333V19.1667ZM2.83333 14.5H5.16667V12.1667H2.83333V14.5ZM2.83333 9.83333H5.16667V7.5H2.83333V9.83333ZM7.5 14.5H9.83333V12.1667H7.5V14.5ZM7.5 9.83333H9.83333V7.5H7.5V9.83333ZM7.5 5.16667H9.83333V2.83333H7.5V5.16667ZM12.1667 14.5H14.5V12.1667H12.1667V14.5ZM12.1667 9.83333H14.5V7.5H12.1667V9.83333ZM12.1667 5.16667H14.5V2.83333H12.1667V5.16667ZM16.8333 19.1667H19.1667V16.8333H16.8333V19.1667ZM16.8333 14.5H19.1667V12.1667H16.8333V14.5Z"
                fill="currentColor"
              />
            </svg>
            &nbsp; {t('application.corporate')}
          </div>
          <div
            className={
              "tab-item " + (formData.userBusType == 0 ? "active" : "")
            }
            onClick={updateForm(() => (formData.userBusType = 0))}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99935 10.0013C8.71602 10.0013 7.6174 9.54436 6.70352 8.63047C5.78963 7.71658 5.33268 6.61797 5.33268 5.33464C5.33268 4.0513 5.78963 2.95269 6.70352 2.0388C7.6174 1.12491 8.71602 0.667969 9.99935 0.667969C11.2827 0.667969 12.3813 1.12491 13.2952 2.0388C14.2091 2.95269 14.666 4.0513 14.666 5.33464C14.666 6.61797 14.2091 7.71658 13.2952 8.63047C12.3813 9.54436 11.2827 10.0013 9.99935 10.0013ZM0.666016 19.3346V16.068C0.666016 15.4069 0.836155 14.7992 1.17643 14.2451C1.51671 13.6909 1.96879 13.268 2.53268 12.9763C3.73824 12.3735 4.96324 11.9214 6.20768 11.6201C7.45213 11.3187 8.71602 11.168 9.99935 11.168C11.2827 11.168 12.5466 11.3187 13.791 11.6201C15.0355 11.9214 16.2605 12.3735 17.466 12.9763C18.0299 13.268 18.482 13.6909 18.8223 14.2451C19.1625 14.7992 19.3327 15.4069 19.3327 16.068V19.3346H0.666016ZM2.99935 17.0013H16.9993V16.068C16.9993 15.8541 16.9459 15.6596 16.8389 15.4846C16.732 15.3096 16.591 15.1735 16.416 15.0763C15.366 14.5513 14.3063 14.1576 13.2368 13.8951C12.1674 13.6326 11.0882 13.5013 9.99935 13.5013C8.91046 13.5013 7.83129 13.6326 6.76185 13.8951C5.6924 14.1576 4.63268 14.5513 3.58268 15.0763C3.40768 15.1735 3.26671 15.3096 3.15977 15.4846C3.05282 15.6596 2.99935 15.8541 2.99935 16.068V17.0013ZM9.99935 7.66797C10.641 7.66797 11.1903 7.4395 11.6473 6.98255C12.1042 6.52561 12.3327 5.9763 12.3327 5.33464C12.3327 4.69297 12.1042 4.14366 11.6473 3.68672C11.1903 3.22977 10.641 3.0013 9.99935 3.0013C9.35768 3.0013 8.80838 3.22977 8.35143 3.68672C7.89449 4.14366 7.66602 4.69297 7.66602 5.33464C7.66602 5.9763 7.89449 6.52561 8.35143 6.98255C8.80838 7.4395 9.35768 7.66797 9.99935 7.66797Z"
                fill="currentColor"
              />
            </svg>
            &nbsp; {t('application.individual')}
          </div>
        </div>
      </div>
      {formData.userBusType == 1 && (
        <>
          <div className="item">
            <div className="label">
              {t('application.enter_company_name')}<b className="cs-form-item_must">*</b>
            </div>
            <BInput
              value={formData.companyName}
              onChange={updateForm(
                (ev) => (formData.companyName = ev.target.value)
              )}
              close
            />
          </div>
          <div className="item">
            <div className="label">
              {t('application.business_registration_certificate')}<b className="cs-form-item_must">*</b>
            </div>
            <div className="d-flex gap-4 upload-img">
              <div className="img" onClick={checkFile}>
                {formData.companyUrl && (
                  <>
                    {formData.companyUrl && (
                      <>
                        {isPdfLink(formData.companyUrl) ? (
                          <iframe
                            className="imgAndPdf"
                            src={formData.companyUrl}
                            alt=""
                          />
                        ) : (
                          <img
                            className="imgAndPdf"
                            src={formData.companyUrl}
                            alt=""
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="option">
                <div>
                  <div className="title">{t('application.supported_file_formats_duplicate')}: PDF, JPG, PNG</div>
                  <div className="tip">
                    {t('application.pdf_file_spec')}
                  </div>
                  <div className="tip">
                    {t('application.image_file_recommendation')}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button className="upload-btn GmarketSans" onClick={checkFile}>
                    <span>Upload</span>
                  </button>
                  <div
                    className="pointer d-flex align-items-center"
                    onClick={updateForm(() => (formData.companyUrl = ""))}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.66797 14C4.3013 14 3.98741 13.8694 3.7263 13.6083C3.46519 13.3472 3.33464 13.0333 3.33464 12.6667V4H2.66797V2.66667H6.0013V2H10.0013V2.66667H13.3346V4H12.668V12.6667C12.668 13.0333 12.5374 13.3472 12.2763 13.6083C12.0152 13.8694 11.7013 14 11.3346 14H4.66797ZM11.3346 4H4.66797V12.6667H11.3346V4ZM6.0013 11.3333H7.33464V5.33333H6.0013V11.3333ZM8.66797 11.3333H10.0013V5.33333H8.66797V11.3333Z"
                        fill="#494F6A"
                      />
                    </svg>
                    &nbsp; Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="item d-flex gap-3">
        <div className="flex-1">
          <div className="label">
            {t('application.last_name')}{formData.userBusType == 1 && `(${t('application.legal_entity')})`}
            <b className="cs-form-item_must">*</b>
          </div>
          <BInput
            close
            value={formData.surname}
            onChange={updateForm((ev) => (formData.surname = ev.target.value))}
          />
        </div>
        <div className="flex-1">
          <div className="label">
            {t('application.first_name')}{formData.userBusType == 1 && `(${t('application.legal_entity')})`}
            <b className="cs-form-item_must">*</b>
          </div>
          <BInput
            close
            value={formData.name}
            onChange={updateForm((ev) => (formData.name = ev.target.value))}
          />
        </div>
      </div>
      <div className="item">
        <div className="label">
          {t('application.phone_number')}<b className="cs-form-item_must">*</b>
        </div>
        <BInput
          value={formData.phonenumber}
          onChange={updateForm(
            (ev) => (formData.phonenumber = ev.target.value)
          )}
        />
      </div>
      {/* 暂时没有短信 先隐藏 */}
      <div className="item">
        <div className="label">
          {t('application.authentication_number')}<b className="cs-form-item_must">*</b>
        </div>
        <BInput
          placeholder={t('application.enter_sms_authentication_code')}
          after={<button className="code">{t('application.send_auth_code')}</button>}
        />
      </div>
      {formData.userBusType == 0 && (<>
        <div className="item d-flex gap-3">
          <div className="flex-1">
            <div className="label">
              {t('application.category')}<b className="cs-form-item_must"></b>
            </div>
            <BSelect
              list={[
                { value: 0, label: t('application.domestic') },
                { value: 1, label: t('application.foreign') },
              ]}
              value={formData.distinction}
              onChange={updateForm((val) => (formData.distinction = val))}
            />
          </div>
          <div className="flex-1">
            <div className="label">
              {t('application.country')}<b className="cs-form-item_must"></b>
            </div>
            <BSelect
              list={countrys}
              type="group"
              value={formData.country}
              onChange={updateForm((val) => (formData.country = val))}
              disabled={!formData.distinction}
            />
          </div>
        </div>
        {formData.distinction == 1 && <div className="item">
          <div className="label">
            {
              t('application.passport_number')
            }
          </div>
          <BInput value={formData.passportNumber} onChange={updateForm((ev) => (formData.passportNumber = ev.target.value))} disabled={!formData.distinction} placeholder={t('application.please_select')} />
        </div>}

      </>
      )}
      <div className="item">
        <div className="label">
          {t('application.application_date')}<b className="cs-form-item_must"></b>
        </div>
        <BInput
          type="date"
          value={formData.appTime}
          onChange={updateForm((ev) => (formData.appTime = ev.target.value))}
        />
      </div>
      <div className="d-flex gap-2 align-items-center">
        <Prev onClick={() => step[1](3)}></Prev>
        <div className="pointer GmarketSans" onClick={() => history.back()}>{t('application.cancel')}</div>
      </div>
      &nbsp;
      {!check && (
        <div className="d-flex gap-3 GmarketSans">
          {!disabled && (
            <>
              <button
                className="cs-btn cs-style1 cs-btn_lg flex-1 hollow"
                onClick={() =>
                  submit(0).then(() => navigate("/nft-application?type=3"))
                }
              >
                <span className="bg-text">{t('application.save')}</span>
              </button>
              <button
                className="cs-btn cs-style1 cs-btn_lg flex-1 w-100"
                onClick={() =>
                  submit(1).then(() => navigate("/nft-application?type=0"))
                }
              >
                <span>{t('application.submit_duplicate')}</span>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};
