import BInput from "@/components/b-input.jsx";
import BSelect from "@/components/b-select.jsx";
import http from "@/http";
import { useEffect, useState } from "react";
import { message } from "antd";
import Prev from "./prev";
import Next from "./next";
import { useTranslation } from "react-i18next";
export default ({
  step,
  formData,
  updateForm,
  technology,
  patent_maturity,
  isEdit,
  submit,
  check,
}) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const checkFile = () => {
    if (disabled) return;
    $('<input type="file" accept=".jpg,.png,.pdf"/>')
      .change(function (event) {
        let form = new FormData();
        form.append("file", this.files[0]);
        http
          .post("/common/upload", form, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            updateForm(() => {
              formData.patentRegistrUrl = res.url;
            })();
          });
      })
      .trigger("click");
  };
  let [disabled, setDisabled] = useState(check);
  // 空拦截
  let toNext = () => {
    // 检查专利注册URL
    if (!formData.patentRegistrUrl) {
      return messageApi.error(t('application.upload_certificate'));
    }

    // 检查专利名
    if (!formData.patentRegistrName) {
      return messageApi.error(t('application.enter_patent_name_duplicate'));
    }

    // 检查技术分类
    if (!formData.patentRegistrTechnology) {
      return messageApi.error(t('application.select_technical_classification'));
    }

    // 检查技术成熟度
    if (!formData.patentMaturity) {
      return messageApi.error(t('application.select_technology_maturity'));
    }

    // 检查专利拥有者
    if (!formData.patenteeBy) {
      return messageApi.error(t('application.enter_patent_owner'));
    }

    // 检查申请者
    if (!formData.patentRegistrAppBy) {
      return messageApi.error(t('application.enter_the_applicant'));
    }

    // 检查申请号
    if (!formData.patentRegistrAppNo) {
      return messageApi.error(t('application.enter_application_number'));
    }

    // 检查申请日期
    // if (!formData.patentRegistrAppTime) {
    //   return messageApi.error("출원일을 입력해 주세요.");
    // }

    // 检查注册号
    // if (!formData.patentRegistrNo) {
    //   return messageApi.error("등록번호를 입력해 주세요.");
    // }

    // 检查注册日期
    // if (!formData.patentRegistrTime) {
    //   return messageApi.error("등록일을 입력해 주세요.");
    // }

    // 检查专利说明
    // if (!formData.patentRegistrNotes) {
    //   return messageApi.error("특허 설명을 입력해 주세요.");
    // }

    // 如果所有必填项都已填写，则调用下一步
    step[1](2);
  };
  function isPdfLink(url) {
    // 使用正则表达式检查链接是否以 .pdf 结尾
    const pdfPattern = /\.pdf$/i;
    return pdfPattern.test(url);
  }
  return (
    <div className={disabled ? "page-disabled" : ""}>
      {contextHolder}
      <h4 className="h-title">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {t('application.patent_data_duplicate_2')}
          </div>
          <div style={{ fontSize: "12px", color: "#96A0CB" }}>
            <b className="cs-form-item_must">*</b>
            {t('application.it_is_necessary')}
          </div>
        </div>
      </h4>
      <div className="item">
        <div className="label">
          {t('application.nft_sales_type_duplicate_3')}<b className="cs-form-item_must">*</b>
        </div>
        <div className="d-flex tab-nav">
          <div
            className={
              "tab-item " + (formData.nftSaleType == 1 ? "active" : "")
            }
            onClick={updateForm(() => (formData.nftSaleType = 1))}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.8346 19.8335C20.3207 19.8335 20.7339 19.6634 21.0742 19.3231C21.4145 18.9828 21.5846 18.5696 21.5846 18.0835C21.5846 17.5974 21.4145 17.1842 21.0742 16.8439C20.7339 16.5036 20.3207 16.3335 19.8346 16.3335C19.3485 16.3335 18.9353 16.5036 18.5951 16.8439C18.2548 17.1842 18.0846 17.5974 18.0846 18.0835C18.0846 18.5696 18.2548 18.9828 18.5951 19.3231C18.9353 19.6634 19.3485 19.8335 19.8346 19.8335ZM19.8346 23.3335C20.4374 23.3335 20.9916 23.1925 21.4971 22.9106C22.0027 22.6286 22.411 22.2543 22.7221 21.7877C22.2944 21.5349 21.8374 21.3404 21.3513 21.2043C20.8652 21.0682 20.3596 21.0002 19.8346 21.0002C19.3096 21.0002 18.8041 21.0682 18.318 21.2043C17.8319 21.3404 17.3749 21.5349 16.9471 21.7877C17.2582 22.2543 17.6666 22.6286 18.1721 22.9106C18.6777 23.1925 19.2319 23.3335 19.8346 23.3335ZM14.0013 25.6668C11.2985 24.9863 9.06727 23.4356 7.30755 21.0147C5.54783 18.5939 4.66797 15.9057 4.66797 12.9502V5.8335L14.0013 2.3335L23.3346 5.8335V12.4543C22.9652 12.2988 22.586 12.1578 22.1971 12.0314C21.8082 11.905 21.4096 11.8127 21.0013 11.7543V7.46683L14.0013 4.84183L7.0013 7.46683V12.9502C7.0013 13.8641 7.12283 14.7779 7.36589 15.6918C7.60894 16.6057 7.94922 17.4759 8.38672 18.3022C8.82422 19.1286 9.35408 19.8918 9.9763 20.5918C10.5985 21.2918 11.2888 21.8752 12.0471 22.3418C12.261 22.9641 12.543 23.5571 12.893 24.121C13.243 24.6849 13.6416 25.1904 14.0888 25.6377C14.0694 25.6377 14.0548 25.6425 14.0451 25.6522C14.0353 25.662 14.0207 25.6668 14.0013 25.6668ZM19.8346 25.6668C18.2207 25.6668 16.8451 25.0981 15.7076 23.9606C14.5701 22.8231 14.0013 21.4474 14.0013 19.8335C14.0013 18.2196 14.5701 16.8439 15.7076 15.7064C16.8451 14.5689 18.2207 14.0002 19.8346 14.0002C21.4485 14.0002 22.8242 14.5689 23.9617 15.7064C25.0992 16.8439 25.668 18.2196 25.668 19.8335C25.668 21.4474 25.0992 22.8231 23.9617 23.9606C22.8242 25.0981 21.4485 25.6668 19.8346 25.6668Z"
                fill="currentColor"
              />
            </svg>
            &nbsp; {t('application.sponsorship')}
          </div>
          <div
            className={
              "tab-item " + (formData.nftSaleType == 2 ? "active" : "")
            }
            onClick={updateForm(() => (formData.nftSaleType = 2))}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2888 15.9835L12.3096 12.6585L9.6263 10.5002H12.9513L14.0013 7.2335L15.0513 10.5002H18.3763L15.6638 12.6585L16.6846 15.9835L14.0013 13.9127L11.2888 15.9835ZM7.0013 26.8335V17.821C6.26241 17.0043 5.6888 16.071 5.28047 15.021C4.87214 13.971 4.66797 12.8529 4.66797 11.6668C4.66797 9.06127 5.57214 6.85433 7.38047 5.046C9.1888 3.23766 11.3957 2.3335 14.0013 2.3335C16.6069 2.3335 18.8138 3.23766 20.6221 5.046C22.4305 6.85433 23.3346 9.06127 23.3346 11.6668C23.3346 12.8529 23.1305 13.971 22.7221 15.021C22.3138 16.071 21.7402 17.0043 21.0013 17.821V26.8335L14.0013 24.5002L7.0013 26.8335ZM14.0013 18.6668C15.9457 18.6668 17.5985 17.9863 18.9596 16.6252C20.3207 15.2641 21.0013 13.6113 21.0013 11.6668C21.0013 9.72238 20.3207 8.06961 18.9596 6.7085C17.5985 5.34738 15.9457 4.66683 14.0013 4.66683C12.0569 4.66683 10.4041 5.34738 9.04297 6.7085C7.68186 8.06961 7.0013 9.72238 7.0013 11.6668C7.0013 13.6113 7.68186 15.2641 9.04297 16.6252C10.4041 17.9863 12.0569 18.6668 14.0013 18.6668ZM9.33464 23.3627L14.0013 22.1668L18.668 23.3627V19.746C17.9874 20.1349 17.2534 20.4411 16.4659 20.6647C15.6784 20.8884 14.8569 21.0002 14.0013 21.0002C13.1457 21.0002 12.3242 20.8884 11.5367 20.6647C10.7492 20.4411 10.0152 20.1349 9.33464 19.746V23.3627Z"
                fill="currentColor"
              />
            </svg>
            &nbsp; {t('application.transaction')}
          </div>
        </div>
      </div>
      <div className="item">
        <div className="label">
          {t('application.upload_patent_certificate')}<b className="cs-form-item_must">*</b>
        </div>
        <div className="d-flex gap-4 upload-img">
          <div className="img" onClick={checkFile}>
            {formData.patentRegistrUrl && (
              <>
                {isPdfLink(formData.patentRegistrUrl) ? (
                  <iframe
                    className="imgAndPdf"
                    src={formData.patentRegistrUrl}
                    alt=""
                  />
                ) : (
                  <img
                    className="imgAndPdf"
                    src={formData.patentRegistrUrl}
                    alt=""
                  />
                )}
              </>
            )}
          </div>
          <div className="option">
            <div style={{ letterSpacing: "-0.5px" }}>
              <div className="title">{t('application.supported_file_formats_duplicate')}: PDF, JPG, PNG</div>
              <div className="tip">
                {t('application.pdf_file_spec')}
              </div>
              <div className="tip">
                {t('application.image_file_spec_duplicate')}
              </div>
              {/* <div className="title">지원되는 파일 형식</div>
              <div className="tip">
                PG, PNG, GIF, SVG, MP4 다양한 유형의 파일에 대한 치수 요구사항은 다음과 같습니다.
              </div>
              <div className="tip">
                아바타(320 x 320px), 가로(1080 x 566px), 세로(1080 x 1350px), 사각형(1080 x 1080px)
              </div> */}
            </div>
            <div className="d-flex justify-content-between align-items-center ">
              <button className="upload-btn GmarketSans" onClick={checkFile}>
                <span>Upload</span>
              </button>
              <div
                className="pointer d-flex align-items-center"
                onClick={updateForm(() => (formData.patentRegistrUrl = ""))}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.66797 14C4.3013 14 3.98741 13.8694 3.7263 13.6083C3.46519 13.3472 3.33464 13.0333 3.33464 12.6667V4H2.66797V2.66667H6.0013V2H10.0013V2.66667H13.3346V4H12.668V12.6667C12.668 13.0333 12.5374 13.3472 12.2763 13.6083C12.0152 13.8694 11.7013 14 11.3346 14H4.66797ZM11.3346 4H4.66797V12.6667H11.3346V4ZM6.0013 11.3333H7.33464V5.33333H6.0013V11.3333ZM8.66797 11.3333H10.0013V5.33333H8.66797V11.3333Z"
                    fill="#494F6A"
                  />
                </svg>
                &nbsp; Delete
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="label">
          {t('application.patent_name')}<b className="cs-form-item_must">*</b>
        </div>
        <BInput
          value={formData.patentRegistrName}
          onChange={updateForm(
            (ev) => (formData.patentRegistrName = ev.target.value)
          )}
        ></BInput>
      </div>
      <div className="item">
        <div className="label">
          {t('application.technical_class')}<b className="cs-form-item_must">*</b>
        </div>
        <BSelect
          list={technology}
          value={formData.patentRegistrTechnology}
          onChange={updateForm(
            (val) => (formData.patentRegistrTechnology = val)
          )}
        />
      </div>
      <div className="item">
        <div className="label">
          {t('application.technological_maturity')}<b className="cs-form-item_must">*</b>
        </div>
        <BSelect
          list={patent_maturity}
          value={formData.patentMaturity}
          onChange={updateForm((val) => (formData.patentMaturity = val))}
        />
      </div>
      <div className="item d-flex gap-3">
        <div className="flex-fill">
          <div className="label">
            {t('application.patent_owner')}<b className="cs-form-item_must">*</b>
          </div>
          <BInput
            value={formData.patenteeBy}
            onChange={updateForm(
              (ev) => (formData.patenteeBy = ev.target.value)
            )}
          />
        </div>
        <div className="flex-fill">
          <div className="label">
            {t('application.applicant')}<b className="cs-form-item_must">*</b>
          </div>
          <BInput
            value={formData.patentRegistrAppBy}
            onChange={updateForm(
              (ev) => (formData.patentRegistrAppBy = ev.target.value)
            )}
          />
        </div>
      </div>
      <div className="item">
        <div className="label">
          {t('application.application_number')}<b className="cs-form-item_must">*</b>
        </div>
        <BInput
          value={formData.patentRegistrAppNo}
          onChange={updateForm(
            (ev) => (formData.patentRegistrAppNo = ev.target.value)
          )}
        />
      </div>
      <div className="item">
        <div className="label">{t('application.filing_date')}</div>
        <BInput
          type="date"
          value={formData.patentRegistrAppTime}
          onChange={updateForm(
            (ev) => (formData.patentRegistrAppTime = ev.target.value)
          )}
        />
      </div>
      <div className="item">
        <div className="label">{t('application.registration_number')}</div>
        <BInput
          value={formData.patentRegistrNo}
          onChange={updateForm(
            (ev) => (formData.patentRegistrNo = ev.target.value)
          )}
        />
      </div>
      <div className="item">
        <div className="label">
          {t('application.date_registration')}<b className="cs-form-item_must"></b>
        </div>
        <BInput
          type="date"
          value={formData.patentRegistrTime}
          onChange={updateForm(
            (ev) => (formData.patentRegistrTime = ev.target.value)
          )}
        />
      </div>
      <div className="item">
        <div className="label">
          {t('application.patent_description')}<b className="cs-form-item_must"></b>
        </div>
        <textarea
          className="textarea"
          value={formData.patentRegistrNotes}
          disabled={disabled}
          onChange={updateForm(
            (ev) => (formData.patentRegistrNotes = ev.target.value)
          )}
        ></textarea>
      </div>
      <div className="d-flex">
        <div className="pointer GmarketSans" onClick={() => history.back()}>{t('application.cancel')}</div>
        <Next onClick={toNext}></Next>
      </div>
    </div>
  );
};
