import { useWallet } from "../WalletContext";
import { useEffect, useState } from "react";
import { getTokenURI } from "../utils/ethNft.js";
import axios from "axios";
import http from "../http";
import store from "@/store";
import Web3 from "web3";
import { message } from "antd";
import contractABIstr from "../utils/contractABI.json";
import { useTranslation } from "react-i18next";
import MineLayout from "@/components/mine-layout.jsx";
export default function UserItems() {
  const { t } = useTranslation();
  const { wallet } = useWallet();
  let [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const enumerates = {
    start: t("userItems.unpaid"),
    confirm_start: t("userItems.payment_processing"),
    confirm_end: t("userItems.payment_success"),
    transfer_nft: t("userItems.nft_transfer_success"),
    pay_fail: t("userItems.payment_timeout"),
    confirm_fail: t("userItems.payment_failed"),
    transfer_fail: t("userItems.nft_transfer_failed"),
  };

  const onOpensea = (_val) => {
    if (_val.bpt_nft_order_status == "transfer_nft") {
      window.open(
        `https://opensea.io/assets/ethereum/${_val.contract_address}/${_val.token_id}`
      );
    }
  };

  const checkTokenBalance = async (tokenAddress, account, amountToSend) => {
    const web3 = new Web3(window.ethereum);
    const tokenContract = new web3.eth.Contract(contractABIstr, tokenAddress);

    try {
      // 获取代币余额
      const balance = await tokenContract.methods.balanceOf(account).call();
      const balanceInEth = web3.utils.fromWei(balance, "ether");
      console.log("balanceInEth:", balanceInEth);
      console.log("amountToSend:", amountToSend);
      // 判断余额是否足够
      if (parseFloat(balanceInEth) < parseFloat(amountToSend)) {
        throw new Error("Insufficient token balance");
      }
      return true;
    } catch (error) {
      console.error("Error checking token balance:", error);
      setErrorMessage(t("userItems.insufficient_token_balance"));
      message.error(t("userItems.insufficient_token_balance"));
      return false;
    }
  };
  const onPayment = async (_val) => {
    console.log("_val", _val);
    // const isBpt = await checkTokenBalance(
    //   _val.pay_token_address,
    //   wallet.account,
    //   _val.pay_token_number
    // );
    // if (!isBpt) return message.error("userItems.insufficient_bpt_balance");

    const web3 = new Web3(window.ethereum);
    const payTokenAddress = _val.pay_token_address;
    const recipientAddress = _val.wallet_address;
    const account = wallet?.account;
    const amountToSend = _val.pay_token_number; // 代币数量
    if (window.ethereum) {
      try {
        // 请求MetaMask授权
        await window.ethereum.request({ method: "eth_requestAccounts" });

        store.dispatch({ type: "showLoading" });
        const tokenContract = new web3.eth.Contract(
          contractABIstr,
          payTokenAddress
        );
        const amountInWei = web3.utils.toWei(amountToSend.toString(), "ether");
        await tokenContract.methods
          .transfer(recipientAddress, amountInWei)
          .send({ from: account })
          .on("transactionHash", (hash) => {
            console.log("Transaction hash:", hash);
            setErrorMessage(null); // 清除之前的错误消息
            store.dispatch({ type: "hideLoading" });
          })
          .on("receipt", (receipt) => {
            console.log("Transaction receipt:", receipt);
            store.dispatch({ type: "hideLoading" });
          })
          .on("error", (error) => {
            console.error("Transaction error:", error);
            store.dispatch({ type: "hideLoading" });
            if (error.message.includes("User denied transaction signature")) {
              setErrorMessage(t("userItems.transaction_declined"));
              message.error(t("userItems.transaction_declined"));
            } else {
              setErrorMessage(t("userItems.transaction_failed"));
              message.error(t("userItems.transaction_failed"));
            }
          });
      } catch (error) {
        console.error("MetaMask authorization error:", error);
        store.dispatch({ type: "hideLoading" });
        if (error.code === 4001) {
          setErrorMessage(t("userItems.metamask_license_denied"));
          message.error(t("userItems.metamask_license_denied"));
        } else {
          setErrorMessage(t("userItems.metamask_auth_failed"));
          message.error(t("userItems.metamask_auth_failed"));
        }
      }
    } else {
      console.error("MetaMask not detected");
      store.dispatch({ type: "hideLoading" });
      setErrorMessage(t("userItems.metamask_not_detected"));
      message.error(t("userItems.metamask_not_detected"));
    }
  };
  useEffect(() => {
    if (!wallet?.account) return;
    http
      .getnft("/bpt-nft/nft_order_list", {
        address: wallet?.account,
        take: 100,
      })
      .then(async (_result) => {
        const data = await Promise.all(
          window._.map(_result.list, async (_item) => {
            // 获取 token URI
            const _nft = await http
              .getnft(
                `/bpt-nft/nft_info?token_id=${_item.token_id}&address=${_item.contract_address}`
              )
              .then((res) => {
                return res.info.nfi_info;
              });
            return {
              ..._item,
              ..._nft,
            };
          })
        );
        setData(data);
        console.log("获取列表数据=====", data);
      });
  }, wallet?.account);

  return (
    <>
      <MineLayout>
        <div className="cs-profile_right">
          <div className="cs-height_30 cs-height_lg_30"></div>
          <h2 className="cs-section_heading cs-style1">
            {t("userItems.my_items")}
          </h2>
          <div className="cs-height_25 cs-height_lg_25"></div>
          <div className="row">
            {data.map((item) => (
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="cs-card cs-style4 cs-box_shadow cs-white_bg  position-relative">
                  {item.bpt_nft_order_status != "start" ? (
                    <div className="sw-status">
                      {enumerates[item.bpt_nft_order_status]}
                    </div>
                  ) : (
                    ""
                  )}
                  <a
                    href="javascript:void(0);"
                    className="cs-card_thumb cs-zoom_effect"
                  >
                    <img
                      src={item.image}
                      alt="Image"
                      className="cs-zoom_item"
                    />
                  </a>
                  <div className="cs-card_info" style={{ marginTop: 0 }}>
                    <h3 className="cs-card_title">
                      <a href="#/explore-details">{item.name}</a>
                    </h3>
                    {/* <div className="cs-card_price">
                        Price: <b className="cs-primary_color">0.29 NFT 7/21</b>
                      </div> */}
                    {/* <hr />
                      <div className="cs-card_footer">
                        <span className="cs-card_btn_1" data-modal="#history_1">
                 
                        </span>
                      </div> */}
                    {item.bpt_nft_order_status == "start" ? (
                      <div className="cs-card_footer">
                        <a
                          href="javascript:void(0);"
                          onClick={() => onPayment(item)}
                          className="cs-card_btn_2"
                          data-params={JSON.stringify(item)}
                        >
                          <span>{t("components.purchase")}</span>
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="cs-height_30 cs-height_lg_30"></div>
              </div>
            ))}
          </div>
          {/* <div className="cs-height_10 cs-height_lg_10"></div> */}
          {/* <div className="text-center">
              <a href="#" className="cs-btn cs-style1 cs-btn_lg">
                <span>Load More</span>
              </a>
            </div> */}
        </div>
      </MineLayout>
    </>
  );
}
