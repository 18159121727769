import { useState, useEffect } from "react"
import PagingBtn from "./paging-btn.jsx"
export default ({ request, pageSize = 10, children }) => {
    let [detail, setDetail] = useState({
        total: 0
    })
    let [currentPage, setCurrpentpage] = useState(1)
    useEffect(() => {
        getList()
    }, [])
    let getList = () => {
        request && request({ pageSize, pageNum: currentPage }).then(res => {
            setDetail(res)
            // setCurrpentpage(res.current)
        }).catch(err => { })
    }
    // getList()

    let toPage = (val) => {
        currentPage = val
        setCurrpentpage(val)
        getList()
    }
    let prev = () => {
        if (currentPage > 1) {
            setCurrpentpage(--currentPage)
            getList()
        }
    }
    let next = () => {
        if (currentPage < detail.pages) {
            setCurrpentpage(++currentPage)
            getList()
        }
    }
    const getPagingList = (num, currentPage) => {
        const list = [];
        let preEps = false, nextEps = false;
        for (let i = 1; i <= num; i++) {
            if (i == 1 || i == num) {
                list.push({ type: 'number', value: i, active: currentPage == i })
            } else if (i - currentPage < -2) {
                if (preEps) continue;
                list.push({ type: 'eps', value: i })
                preEps = true
            } else if (i - currentPage > 2) {
                if (nextEps) continue;
                list.push({ type: 'eps', value: i })
                nextEps = true
            } else {
                list.push({ type: 'number', value: i, active: currentPage == i })
            }
        }
        if (list.length == 0) return [{ type: 'number', value: 1, active: currentPage == 1 }]
        return list;
    }
    let render = (fun) => (
        <>
            {fun(detail.rows || [], detail)}
            <PagingBtn prev={prev} next={next} toPage={toPage} list={getPagingList(Math.ceil(detail.total / pageSize) || 0, currentPage)}></PagingBtn>
        </>
    )
    render.reset = () => {
        toPage(1)
    }
    render.getTotal = () => detail.total
    return render
}