import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="home-boxes">
        <div className="container">
          <h3 className="title">
            <img className="icon" src="/assets/img/home/boxes.png" alt="" />
            <span>
              {t("home.platform_advantages")}
            </span>
          </h3>
          <div className="row gap-md">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="row group">
                <div className="col-md-6 d-flex">
                  <div className="d-flex item">
                    <img
                      className="icon"
                      src="/assets/img/home/01.png"
                      alt=""
                    />

                    <div
                      className="info"
                      dangerouslySetInnerHTML={{
                        __html: t("home.reward_inventors"),
                      }}
                    ></div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="d-flex item">
                    <img
                      className="icon"
                      src="/assets/img/home/02.png"
                      alt=""
                    />
                    <div
                      className="info"
                      dangerouslySetInnerHTML={{
                        __html: t("home.bpt_token"),
                      }}
                    ></div>
                  </div>
                </div>
                <div className="col-md-6 d-flex ">
                  <div className="d-flex item">
                    <img
                      className="icon"
                      src="/assets/img/home/03.png"
                      alt=""
                    />
                    <div
                      className="info"
                      dangerouslySetInnerHTML={{
                        __html: t("home.digital_assets"),
                      }}
                    ></div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="d-flex item">
                    <img
                      className="icon"
                      src="/assets/img/home/04.png"
                      alt=""
                    />
                    <div
                      className="info"
                      dangerouslySetInnerHTML={{
                        __html: t("home.ai_drafting_tool"),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
