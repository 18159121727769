// src/WalletContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import Web3 from "web3";
import http from "./http";
const WalletContext = createContext();

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState({
    web3: null,
    account: null,
    balance: 0,
  });

  const connectWallet = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      const web3 = new Web3(window.ethereum);
      try {
        // 请求账户访问以触发 MetaMask 弹出窗口
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        const balances = await web3.eth.getBalance(accounts[0]);
        const balance = await web3.utils.fromWei(balances, "ether");
        setWallet({ web3, account: accounts[0], balance: balance });
        localStorage.setItem("isWalletConnected", "true");
        localStorage.setItem("account", accounts[0]);
        console.log("获取钱包地址====", accounts);
        http.post("/system/user/updateLastWallet", {
          lastWallet: accounts[0],
        });
      } catch (error) {
        console.error("用户拒绝了账户访问", error);
      }
    } else {
      if (localStorage.getItem("language") == "ko") {
        window.open("https://metamask.io/ko/download/", "_blank");
      } else {
        window.open("https://metamask.io/download.html", "_blank");
      }
      // alert("未检测到 MetaMask，请安装 MetaMask 插件以使用该应用。");
      // 跳转到 MetaMask 官网下载页面
    }
  };

  const disconnectWallet = () => {
    setWallet({ web3: null, account: null, balance: 0 });
    localStorage.removeItem("isWalletConnected");
    localStorage.removeItem("account");
  };

  useEffect(() => {
    const autoConnect = async () => {
      const isWalletConnected = localStorage.getItem("isWalletConnected");
      const savedAccount = localStorage.getItem("account");

      if (isWalletConnected === "true" && savedAccount) {
        const web3 = new Web3(window.ethereum);
        const balances = await web3.eth.getBalance(savedAccount);
        const balance = await web3.utils.fromWei(balances, "ether");

        // 从 localStorage 恢复钱包信息
        setWallet({ web3, account: savedAccount, balance: balance });
      }
    };

    if (window.ethereum && window.ethereum.isMetaMask) {
      autoConnect();
    }
  }, []);

  const clearAppState = () => {
    // 清除与钱包连接相关的应用状态或缓存
    localStorage.removeItem("isWalletConnected");
    setWallet({ web3: null, account: null });
  };

  return (
    <WalletContext.Provider
      value={{ wallet, connectWallet, disconnectWallet, clearAppState }}
    >
      {children}
    </WalletContext.Provider>
  );
};
