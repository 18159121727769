export default ({ checked, onClick, className }) => {
  return <div className={"d-inline-block " + className} onClick={onClick}>
    {
      checked ? <svg className="d-block" width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7" filter="url(#filter0_b_3319_34663)">
          <rect width="14" height="14" rx="2" fill="white" />
        </g>
        <path d="M6.27485 9.43684L10.2156 5.49609L9.25633 4.53683L6.27485 7.51832L4.77114 6.01461L3.81189 6.97387L6.27485 9.43684ZM3.37114 12.0035C2.99323 12.0035 2.66976 11.8689 2.40074 11.5998C2.13163 11.3308 1.99707 11.0073 1.99707 10.6294V3.37017C1.99707 2.99234 2.13163 2.66887 2.40074 2.39976C2.66976 2.13065 2.99323 1.99609 3.37114 1.99609H10.6304C11.0082 1.99609 11.3317 2.13065 11.6008 2.39976C11.8699 2.66887 12.0045 2.99234 12.0045 3.37017V10.6294C12.0045 11.0073 11.8699 11.3308 11.6008 11.5998C11.3317 11.8689 11.0082 12.0035 10.6304 12.0035H3.37114Z" fill="#3772FF" />
        <defs>
          <filter id="filter0_b_3319_34663" x="-31.1" y="-31.1" width="76.2" height="76.2" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="15.55" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3319_34663" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3319_34663" result="shape" />
          </filter>
        </defs>
      </svg>
        : <svg className="d-block" width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.7" filter="url(#filter0_b_0_1)">
            <rect width="14" height="14" rx="2" fill="white" />
          </g>
          <path d="M3.37017 12.0035C2.99225 12.0035 2.66879 11.8689 2.39976 11.5998C2.13065 11.3308 1.99609 11.0073 1.99609 10.6294V3.37017C1.99609 2.99234 2.13065 2.66887 2.39976 2.39976C2.66879 2.13065 2.99225 1.99609 3.37017 1.99609H10.6294C11.0073 1.99609 11.3307 2.13065 11.5998 2.39976C11.8689 2.66887 12.0035 2.99234 12.0035 3.37017V10.6294C12.0035 11.0073 11.8689 11.3308 11.5998 11.5998C11.3307 11.8689 11.0073 12.0035 10.6294 12.0035H3.37017ZM3.37017 10.6294H10.6294V3.37017H3.37017V10.6294Z" fill="#2B2B2B" />
          <defs>
            <filter id="filter0_b_0_1" x="-31.1" y="-31.1" width="76.2" height="76.2" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="15.55" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_0_1" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_0_1" result="shape" />
            </filter>
          </defs>
        </svg>
    }
  </div>
}