import { useState } from "react";
import http from "../http";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import store from "@/store";
import { useTranslation } from "react-i18next";
import Close from "@/components/close.jsx";
import Eye from "@/components/eye.jsx";
import "@/assets/sass/login.scss";
export default function Login() {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  let notOpen = () => messageApi.error(t("login.not_open_yet"));
  let [lastTime, setLastTime] = useState(0);
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [code, setCode] = useState("");
  let [uuid, setUuid] = useState("");
  let [showEye, setShowEye] = useState(false);
  let [showEye1, setShowEye1] = useState(false);
  let sendMsgTime;
  let sendMsg = () => {
    // if (lastTime != 0) return;
    http
      .post("/getResetCode", {
        email,
      })
      .then((res) => {
        setUuid(res.uuid);
        lastTime = 60;
        setLastTime(lastTime);
        sendMsgTime = setInterval(() => {
          if (lastTime <= 0) {
            clearInterval(sendMsgTime);
            setLastTime(0);
            return;
          }
          lastTime--;
          setLastTime(lastTime);
        }, 1000);
      })
      .catch((err) => {});
  };
  let back = () => navigate(-1);

  let reset = (event) => {
    event && event.preventDefault();
    let emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailReg.test(email)) {
      messageApi.open({
        type: "error",
        content: t("register.input_email"),
      });
      return;
    }
    if (!code) {
      messageApi.open({
        type: "error",
        content: t("register.input_auth_code"),
      });
      return;
    }
    let passwordReg = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    if (!passwordReg.test(password)) {
      messageApi.open({
        type: "error",
        content: t("register.input_password"),
      });
      return;
    }
    if (password != confirmPassword) {
      messageApi.open({
        type: "error",
        content: t("register.password_mismatch"),
      });
      return;
    }
    http
      .post("/reset", {
        email,
        password,
        confirmPassword,
        uuid,
        code,
      })
      .then((res) => {
        navigate("/login");
      })
      .catch(() => {});
  };
  return (
    <div className="login">
      {/* End Header Section */}
      {contextHolder}
      <div className="cs-height_90 cs-height_lg_80"></div>
      <div className="box">
        <div className="left"></div>
        <div className="right">
          <form className="cs-form_card" onSubmit={(e) => e.preventDefault()}>
            <h2 className="cs-form_title pointer" onClick={back}>
              <svg
                width="17"
                height="24"
                viewBox="0 0 17 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7565 23.8724L0.839844 11.9557L12.7565 0.0390625L16.1482 3.43073L7.62318 11.9557L16.1482 20.4807L12.7565 23.8724Z"
                  fill="#2B2B2B"
                />
              </svg>
              &nbsp;
              {t("login.forgot_password")}
            </h2>
            <p className="cs-form_tip">
              {t("register.enter_email_for_verification")}
            </p>
            <div className="cs-form_card_in">
              <div className="cs-form_label">{t("register.email")}</div>
              <div className="cs-form_field_wrap">
                <div className="cs-form_field cs-form-item_field">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("login.email")}
                  />
                  {email && <Close onClick={() => setEmail("")} />}
                </div>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="cs-form_label">{t("register.auth_code")}</div>
              <div className="cs-form_field_wrap">
                <div
                  className="cs-form_field cs-form-item_field"
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    height: "52px",
                  }}
                >
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder={t("register.input_auth_code")}
                  />
                  {!code && (
                    <div onClick={sendMsg} className="code pointer">
                      {t("register.send_auth_code")}
                    </div>
                  )}
                </div>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="cs-form-item">
                <label className="cs-form_label">
                  {t("register.new_password")}
                </label>
                <div className="cs-form_field_wrap">
                  <div className="cs-form_field cs-form-item_field">
                    <input
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      type={showEye ? "text" : "password"}
                      placeholder={t("register.password_requirements")}
                    />
                    <Eye show={showEye} onClick={() => setShowEye(!showEye)} />
                  </div>
                </div>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="cs-form-item">
                <label className="cs-form_label">
                  {t("register.confirm_password")}
                </label>
                <div className="cs-form_field_wrap">
                  <div className="cs-form_field cs-form-item_field">
                    <input
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                      type={showEye1 ? "text" : "password"}
                      placeholder={t("register.password_requirements")}
                    />
                    <Eye
                      show={showEye1}
                      onClick={() => setShowEye1(!showEye1)}
                    />
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
              <button
                className="cs-btn cs-style1 cs-btn_lg w-100"
                onClick={reset}
              >
                <span>{t("register.confirm")}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
