import http from "@/http";
import { useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import "@/assets/sass/contact.scss";
export default function Contact() {
  const { t } = useTranslation();
  let [title, setTitle] = useState("");
  let [content, setContent] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  let submit = (event) => {
    event && event.preventDefault();
    if (!title) {
      messageApi.open({
        type: "error",
        content: t("contact.input_title"),
      });
      return;
    }
    if (!content) {
      messageApi.open({
        type: "error",
        content: t("contact.input_content"),
      });
      return;
    }
    http
      .post("/system/feedback", {
        title,
        content,
      })
      .then((res) => {
        setTitle("");
        setContent("");
      })
      .catch((res) => { });
  };
  return (
    <>
      {contextHolder}

      {/* End Header Section */}

      <div className="cs-height_90 cs-height_lg_80"></div>
      {/* Start Page Head */}
      <section
        className="cs-page_head cs-bg cs-page_head300"
        data-src="/assets/img/contactbg.jpg"
      >
        <div className="container">
          <div className="text-center">
            {/* <ol className="breadcrumb" style={{ color: "#fff" }}>
              <li className="breadcrumb-item">
                <a href="#/index">{t("contact.home")}</a>
              </li>
              <li className="breadcrumb-item active" style={{ color: "#fff" }}>
                {t("contact.customer_inquiry")}
              </li>
            </ol> */}
            <h1 className="cs-page_title" style={{ color: "#fff" }}>
              {t("contact.customer_inquiry")}
            </h1>
            <img src="/assets/img/line.png" alt="Avatar" />
          </div>
        </div>
      </section>
      {/* End Page Head */}
      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container contact-container">
        <div className="contact-flex d-md-flex">
          <div className="cs-contact_box contact-form">
            <div className="cs-section_heading cs-style4">
              <h2 className="cs-section_title">
                <span>{t("contact.inquiry_content")}</span>
              </h2>
              <p className="cs-section_subtitle">{t("contact.thank_you")}</p>
            </div>
            <div className="cs-height_20 cs-height_lg_20"></div>
            <form className="cs-contact_form">
              <div className="row">
                <div className="col-lg-12">
                  <div className="title">{t("contact.title")}</div>
                  <div className="cs-form_field_wrap">
                    <input
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                      type="text"
                      className="cs-form_field"
                      placeholder={t("contact.title")}
                    />
                  </div>
                  <div className="cs-height_20 cs-height_lg_20"></div>
                </div>
                <div className="col-lg-12">
                  <div className="title">
                    {t("contact.consultation_content")}
                  </div>
                  <div className="cs-form_field_wrap">
                    <textarea
                      value={content}
                      onChange={(event) => setContent(event.target.value)}
                      cols="30"
                      rows="5"
                      placeholder={t("contact.inquiry_content")}
                      className="cs-form_field"
                    ></textarea>
                  </div>
                  <div className="cs-height_20 cs-height_lg_20"></div>
                </div>
                <div className="col-lg-12" onClick={submit}>
                  <button className="cs-btn cs-style1 cs-btn_lg">
                    <span>{t("contact.send")}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="cs-contact_card_wrap">
            <div className="cs-contact_card">
              <div className="cs-contact_info text-center">
                <div className="cs-contact_icon">
                  <svg
                    width="58"
                    height="58"
                    viewBox="0 0 58 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.66634 48.3333C8.33717 48.3333 7.19933 47.8601 6.2528 46.9135C5.30627 45.967 4.83301 44.8292 4.83301 43.5V14.5C4.83301 13.1708 5.30627 12.033 6.2528 11.0865C7.19933 10.1399 8.33717 9.66667 9.66634 9.66667H48.333C49.6622 9.66667 50.8 10.1399 51.7466 11.0865C52.6931 12.033 53.1663 13.1708 53.1663 14.5V43.5C53.1663 44.8292 52.6931 45.967 51.7466 46.9135C50.8 47.8601 49.6622 48.3333 48.333 48.3333H9.66634ZM28.9997 31.4167L9.66634 19.3333V43.5H48.333V19.3333L28.9997 31.4167ZM28.9997 26.5833L48.333 14.5H9.66634L28.9997 26.5833ZM9.66634 19.3333V14.5V43.5V19.3333Z"
                      fill="url(#paint0_linear_2116_623)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2116_623"
                        x1="4.83301"
                        y1="9.66667"
                        x2="59.7727"
                        y2="25.7098"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0479F7" />
                        <stop offset="0.501404" stopColor="#5A40FF" />
                        <stop offset="0.985" stopColor="#2600BD" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="cs-contact_title">
                  <span>Email</span>
                </h3>
                <p className="cs-contact_text">patentnft@patent-nft.com</p>
              </div>
              <div className="line"></div>
              <div className="cs-contact_info text-center">
                <div className="cs-contact_icon">
                  <svg
                    width="58"
                    height="58"
                    viewBox="0 0 58 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.0003 29C30.3295 29 31.4673 28.5267 32.4139 27.5802C33.3604 26.6337 33.8337 25.4958 33.8337 24.1667C33.8337 22.8375 33.3604 21.6997 32.4139 20.7531C31.4673 19.8066 30.3295 19.3333 29.0003 19.3333C27.6712 19.3333 26.5333 19.8066 25.5868 20.7531C24.6403 21.6997 24.167 22.8375 24.167 24.1667C24.167 25.4958 24.6403 26.6337 25.5868 27.5802C26.5333 28.5267 27.6712 29 29.0003 29ZM29.0003 46.7625C33.9142 42.2514 37.5594 38.1531 39.9357 34.4677C42.3121 30.7823 43.5003 27.5097 43.5003 24.65C43.5003 20.2597 42.1007 16.6649 39.3014 13.8656C36.5021 11.0663 33.0684 9.66667 29.0003 9.66667C24.9323 9.66667 21.4986 11.0663 18.6993 13.8656C15.9 16.6649 14.5003 20.2597 14.5003 24.65C14.5003 27.5097 15.6885 30.7823 18.0649 34.4677C20.4413 38.1531 24.0864 42.2514 29.0003 46.7625ZM29.0003 53.1667C22.5156 47.6486 17.6722 42.5233 14.4701 37.7906C11.268 33.058 9.66699 28.6778 9.66699 24.65C9.66699 18.6083 11.6104 13.7951 15.4972 10.2104C19.384 6.6257 23.885 4.83334 29.0003 4.83334C34.1156 4.83334 38.6166 6.6257 42.5034 10.2104C46.3903 13.7951 48.3337 18.6083 48.3337 24.65C48.3337 28.6778 46.7326 33.058 43.5305 37.7906C40.3284 42.5233 35.485 47.6486 29.0003 53.1667Z"
                      fill="url(#paint0_linear_2116_627)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2116_627"
                        x1="9.66699"
                        y1="4.83334"
                        x2="55.7568"
                        y2="13.447"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0479F7" />
                        <stop offset="0.501404" stopColor="#5A40FF" />
                        <stop offset="0.985" stopColor="#2600BD" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="cs-contact_title">
                  <span>{t("contact.company_location")}</span>
                </h3>
                <p className="cs-contact_text">
                  {t("contact.company_location_text")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cs-height_100 cs-height_lg_70"></div>
    </>
  );
}
