import { useTranslation } from "react-i18next";
import MineLayout from "@/components/mine-layout.jsx";
import http from "../http";
import { message, Modal } from "antd";
import { useEffect, useState } from "react";
import PagingBtn from "@/components/paging-btn.jsx";
import "@/assets/sass/nft-application.scss";
import { useNavigate } from "react-router-dom";
import Paging from "@/components/paging.jsx";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import UploadImgs from "@/page/nft-application-form/components/upload-imgs.jsx";
import { connect } from "react-redux";
import BInput from "@/components/b-input.jsx";
import BSelect from "@/components/b-select.jsx";
import Web3 from "web3";
import nftABI from "../utils/nftabi.json";
export default connect((state) => {
  return {
    language: state.language,
    userInfo: state.userInfo,
  };
})(({ userInfo, language }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, contextModalHolder] = Modal.useModal();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const type = query.get("type");
  let [tab, _setTab] = useState(type || 0);
  let setTab = (idx) => {
    setOldTab(tab);
    _setTab(idx);
  };
  let getList0 = Paging({
    request: (data) => http.get("/system/nftAudit/myNFTIngList", data),
  });
  let getList1 = Paging({
    request: (data) => http.get("/system/nftAudit/myNFTPassList", data),
  });
  let getList2 = Paging({
    request: (data) => http.get("/system/nftAudit/myNFTNotPassList", data),
  });
  let getList3 = Paging({
    request: (data) => http.get("/system/nftAudit/myNFTNotIngList", data),
  });
  let submit = (item) =>
    http
      .post("/system/nftAudit/toAudit", { id: item.id, auditStatus: 1 })
      .then(() => {
        getList0.reset();
        getList1.reset();
        getList2.reset();
        getList3.reset();
      });
  let tipMap = {
    1: t("application.sponsorship"),
    2: t("application.transaction"),
  };
  let [oldTab, setOldTab] = useState(tab);
  let backTab = () => {
    setTab(oldTab);
  };

  const onMiniNft = async (_item) => {
    console.log("_item", _item);
    const web3 = new Web3(window.ethereum);
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();

      if (accounts.length === 0) {
        throw new Error("未找到连接的账户");
      }
      console.log("Connected Account:", accounts[0]);
      const nftContract = new web3.eth.Contract(
        nftABI,
        _item.nft_sale_type == 1
          ? process.env.REACT_APP_CONTRACT_SPONSORSHIP
          : process.env.REACT_APP_CONTRACT_TRADE
      );
      const res = await nftContract.methods
        .mint(_item.collection_number, _item.uuid)
        .send({ from: accounts[0] });

      console.log("Mint成功:", res);
      const _recordNft = await http.post("/mint", {
        id: _item.id,
        audit_id: _item.audit_id,
      });
      // alert("NFT Mint成功!");
    } catch (error) {
      // errorTip()
      if (error.code === 4001 || error.code == 100) {
        // console.warn("用户拒绝了连接请求");
        // alert("连接请求被拒绝,请授权钱包以继续操作。");
      } else {
        // console.error("Mint失败:", error);
        // alert("NFT Mint失败,请稍后再试或检查合约设置。");
        errorTip();
      }
    }
  };

  let toMint = (item) => {
    let mint = modal.confirm({
      className: "mint-modal",
      title: null,
      width: "942px",
      content: (
        <div className="box">
          <h3 className="title">NFT Minting</h3>
          <h4 className="h-title" style={{ maxWidth: "560px" }}>
            {t("application.nft_metadata_duplicate")}
          </h4>
          <div className="d-lg-flex" style={{ gap: "30px" }}>
            <div className="flex-1">
              <div className="item">
                <div className="label">
                  {t("application.nft_sales_type_duplicate")}
                  <b className="cs-form-item_must">*</b>
                </div>
                <div className="d-flex tab-nav">
                  <div
                    className={
                      "tab-item " + (item.nft_sale_type == 1 ? "active" : "")
                    }
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.8346 19.8335C20.3207 19.8335 20.7339 19.6634 21.0742 19.3231C21.4145 18.9828 21.5846 18.5696 21.5846 18.0835C21.5846 17.5974 21.4145 17.1842 21.0742 16.8439C20.7339 16.5036 20.3207 16.3335 19.8346 16.3335C19.3485 16.3335 18.9353 16.5036 18.5951 16.8439C18.2548 17.1842 18.0846 17.5974 18.0846 18.0835C18.0846 18.5696 18.2548 18.9828 18.5951 19.3231C18.9353 19.6634 19.3485 19.8335 19.8346 19.8335ZM19.8346 23.3335C20.4374 23.3335 20.9916 23.1925 21.4971 22.9106C22.0027 22.6286 22.411 22.2543 22.7221 21.7877C22.2944 21.5349 21.8374 21.3404 21.3513 21.2043C20.8652 21.0682 20.3596 21.0002 19.8346 21.0002C19.3096 21.0002 18.8041 21.0682 18.318 21.2043C17.8319 21.3404 17.3749 21.5349 16.9471 21.7877C17.2582 22.2543 17.6666 22.6286 18.1721 22.9106C18.6777 23.1925 19.2319 23.3335 19.8346 23.3335ZM14.0013 25.6668C11.2985 24.9863 9.06727 23.4356 7.30755 21.0147C5.54783 18.5939 4.66797 15.9057 4.66797 12.9502V5.8335L14.0013 2.3335L23.3346 5.8335V12.4543C22.9652 12.2988 22.586 12.1578 22.1971 12.0314C21.8082 11.905 21.4096 11.8127 21.0013 11.7543V7.46683L14.0013 4.84183L7.0013 7.46683V12.9502C7.0013 13.8641 7.12283 14.7779 7.36589 15.6918C7.60894 16.6057 7.94922 17.4759 8.38672 18.3022C8.82422 19.1286 9.35408 19.8918 9.9763 20.5918C10.5985 21.2918 11.2888 21.8752 12.0471 22.3418C12.261 22.9641 12.543 23.5571 12.893 24.121C13.243 24.6849 13.6416 25.1904 14.0888 25.6377C14.0694 25.6377 14.0548 25.6425 14.0451 25.6522C14.0353 25.662 14.0207 25.6668 14.0013 25.6668ZM19.8346 25.6668C18.2207 25.6668 16.8451 25.0981 15.7076 23.9606C14.5701 22.8231 14.0013 21.4474 14.0013 19.8335C14.0013 18.2196 14.5701 16.8439 15.7076 15.7064C16.8451 14.5689 18.2207 14.0002 19.8346 14.0002C21.4485 14.0002 22.8242 14.5689 23.9617 15.7064C25.0992 16.8439 25.668 18.2196 25.668 19.8335C25.668 21.4474 25.0992 22.8231 23.9617 23.9606C22.8242 25.0981 21.4485 25.6668 19.8346 25.6668Z"
                        fill="currentColor"
                      />
                    </svg>
                    &nbsp; {t("application.sponsorship_duplicate")}
                  </div>
                  <div
                    className={
                      "tab-item " + (item.nft_sale_type == 2 ? "active" : "")
                    }
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2888 15.9835L12.3096 12.6585L9.6263 10.5002H12.9513L14.0013 7.2335L15.0513 10.5002H18.3763L15.6638 12.6585L16.6846 15.9835L14.0013 13.9127L11.2888 15.9835ZM7.0013 26.8335V17.821C6.26241 17.0043 5.6888 16.071 5.28047 15.021C4.87214 13.971 4.66797 12.8529 4.66797 11.6668C4.66797 9.06127 5.57214 6.85433 7.38047 5.046C9.1888 3.23766 11.3957 2.3335 14.0013 2.3335C16.6069 2.3335 18.8138 3.23766 20.6221 5.046C22.4305 6.85433 23.3346 9.06127 23.3346 11.6668C23.3346 12.8529 23.1305 13.971 22.7221 15.021C22.3138 16.071 21.7402 17.0043 21.0013 17.821V26.8335L14.0013 24.5002L7.0013 26.8335ZM14.0013 18.6668C15.9457 18.6668 17.5985 17.9863 18.9596 16.6252C20.3207 15.2641 21.0013 13.6113 21.0013 11.6668C21.0013 9.72238 20.3207 8.06961 18.9596 6.7085C17.5985 5.34738 15.9457 4.66683 14.0013 4.66683C12.0569 4.66683 10.4041 5.34738 9.04297 6.7085C7.68186 8.06961 7.0013 9.72238 7.0013 11.6668C7.0013 13.6113 7.68186 15.2641 9.04297 16.6252C10.4041 17.9863 12.0569 18.6668 14.0013 18.6668ZM9.33464 23.3627L14.0013 22.1668L18.668 23.3627V19.746C17.9874 20.1349 17.2534 20.4411 16.4659 20.6647C15.6784 20.8884 14.8569 21.0002 14.0013 21.0002C13.1457 21.0002 12.3242 20.8884 11.5367 20.6647C10.7492 20.4411 10.0152 20.1349 9.33464 19.746V23.3627Z"
                        fill="currentColor"
                      />
                    </svg>
                    &nbsp; {t("application.transaction_duplicate_2")}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="label">
                  {t("application.upload_nft_image_duplicate")}
                  <b className="cs-form-item_must">*</b>
                </div>
                <UploadImgs list={item.nftUrls} />
                <div style={{ fontSize: "12px", color: "#1246C3" }}>
                  {item.nftUrls?.length}
                  {t("application.file_upload_complete_count")}
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="item">
                <div className="label">{t("application.owner_duplicate")}</div>
                <div className="d-flex align-items-center">
                  <img className="avatar me-2" src={userInfo.avatar} alt="" />
                  <div>{userInfo.nickName}</div>
                </div>
              </div>
              <div className="item">
                <div className="label">
                  {t("application.collection_name")}
                  <b className="cs-form-item_must">*</b>
                </div>
                <BInput value={item.collection_name} disabled={true} />
              </div>
              <div className="item">
                <div className="label">
                  {t("application.nft_name_duplicate")}
                  <b className="cs-form-item_must">*</b>
                </div>
                <BInput value={item.nft_name} />
              </div>
              <div className="item">
                <div className="label">
                  {t("application.nft_issuance_count")}
                  <b className="cs-form-item_must">*</b>
                </div>
                <div className="d-flex gap-1 align-items-center">
                  <BSelect
                    className="flex-fill"
                    list={[]}
                    value={item.collection_number}
                    disabled={true}
                  />
                  <span>{t("application.count")}</span>
                </div>
              </div>
              <div className="item">
                <div className="label">
                  {t("application.nft_description")}
                  <b className="cs-form-item_must">*</b>
                </div>
                <textarea
                  className="textarea"
                  value={item.collection_notes}
                  onChange={() => { }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      ),
      footer: (params) => (
        <div className="d-flex gap-3 footer-btn-group">
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 hollow"
            onClick={() => mint.destroy()}
          >
            <span>{t("application.cancel")}</span>
          </button>
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 w-100"
            onClick={() => {
              mint.destroy();
              confirm(item);
            }}
          >
            <span>Minting</span>
          </button>
        </div>
      ),
    });
  };
  let confirm = (item) => {
    let mint = modal.confirm({
      className: "mint-modal",
      title: null,
      width: "652px",
      content: (
        <div className="box">
          <h3 className="title text-center">
            {t("application.minting_guide")}
          </h3>
          <div
            className="text-center mx-auto"
            style={{
              maxWidth: "400px",
              fontSize: "20px",
              marginBottom: "25px",
              fontWeight: 300,
            }}
            dangerouslySetInnerHTML={{
              __html: t("application.minting_fee_notice"),
            }}
          ></div>
        </div>
      ),
      footer: (params) => (
        <div
          className="d-flex gap-3 footer-btn-group mx-auto"
          style={{ maxWidth: "440px" }}
        >
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 hollow"
            onClick={() => mint.destroy()}
          >
            <span>{t("application.cancel")}</span>
          </button>
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 w-100"
            onClick={() => {
              mint.destroy();
              onMiniNft(item);
            }}
          >
            <span>{t("application.next")}</span>
          </button>
        </div>
      ),
    });
  };
  let errorTip = () => {
    let mint = modal.confirm({
      className: "mint-modal",
      title: null,
      width: "652px",
      content: (
        <div className="box">
          <h3 className="title text-center">
            {t("application.minting_failure_notice")}
          </h3>
          <div
            className="error-tip"
            dangerouslySetInnerHTML={{
              __html: t("application.minting_failure_message"),
            }}
          ></div>
        </div>
      ),
      footer: (params) => (
        <div className="d-flex gap-3 footer-btn-group flex-column">
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 "
            onClick={() => mint.destroy()}
          >
            <span>{t("application.confirm")}</span>
          </button>
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 hollow"
            onClick={() => {
              mint.destroy();
            }}
          >
            <span>{t("application.cancel")}</span>
          </button>
        </div>
      ),
    });
  };
  let TimeText = ({ create_time }) => (
    <>
      {language == "ko" && (
        <div className="m">
          <b>{dayjs(create_time).format("M")}</b>
          {t("application.month")}
          <b>{dayjs(create_time).format("D")}</b>
          {t("application.day")}
        </div>
      )}
      {language == "en" && (
        <div className="m">
          <b>{dayjs(create_time).format("MMM D")}</b>
        </div>
      )}
    </>
  );
  return (
    <>
      {contextHolder}
      {contextModalHolder}
      <MineLayout>
        <div className="cs-profile_right nft-application ">
          <div className="cs-height_30 cs-height_lg_30"></div>
          <h2 className="cs-section_heading cs-style1 d-flex justify-content-between">
            <div>{t("mine.my_nft_application")}</div>
            <button className="topBtn" onClick={() => setTab(3)}>
              <span>{t("application.temp_save_list")}</span>
            </button>
          </h2>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <div className="cs-tabs cs-fade_tabs">
            <ul className="cs-tab_links cs-style1  cs-primary_color cs-mp0">
              <li
                className={tab == 0 ? "active" : ""}
                onClick={() => setTab(0)}
              >
                <a>
                  <span className="txt">
                    {t("application.approval_in_progress")}
                  </span>
                  <span className="dage">{getList0.getTotal()}</span>
                </a>
              </li>
              <li
                className={tab == 1 ? "active" : ""}
                onClick={() => setTab(1)}
              >
                <a>
                  <span className="txt">
                    {t("application.approval_complete")}
                  </span>
                  <span className="dage">{getList1.getTotal()}</span>
                </a>
              </li>
              <li
                className={tab == 2 ? "active" : ""}
                onClick={() => setTab(2)}
              >
                <a>
                  <span className="txt">
                    {t("application.approval_rejected")}
                  </span>
                  <span className="dage">{getList2.getTotal()}</span>
                </a>
              </li>
              {/* <li
                className={tab == 3 ? "active" : ""}
                onClick={() => setTab(3)}
              >
                <a>
                  <span className="txt">임시 저장 목록</span>
                  <span className="dage">{getList3.getTotal()}</span>
                </a>
              </li> */}
            </ul>
            <div className="cs-height_20 cs-height_lg_20"></div>
            {tab == 3 && (
              <div
                className="d-flex align-items-center pointer"
                style={{ fontSize: "22px", color: "#2B2B2B" }}
                onClick={backTab}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5625 23.0938L9.4375 14.9688L17.5625 6.84375L19.875 9.15625L14.0625 14.9688L19.875 20.7812L17.5625 23.0938Z"
                    fill="#2B2B2B"
                  />
                </svg>
                {t("application.temp_save_list")}
              </div>
            )}

            <div className="cs-tab_content">
              {tab == 0 && (
                <div className="cs-tab active">
                  {getList0((list) => (
                    <>
                      {list.map((item, idx) => (
                        <div
                          className="order d-md-flex"
                          key={item.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/nft-application-form?id=${item.id}&check=1`
                            );
                          }}
                        >
                          <div className="d-flex flex-column justify-content-between align-items-start me-4">
                            <div className="time">
                              <div className="year">
                                {dayjs(item.create_time).format("YYYY")}
                              </div>
                              <TimeText create_time={item.create_time} />
                            </div>
                            <div
                              className="bg-text fs-4 d-inline-block"
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  "application.approval_in_progress_duplicate"
                                ),
                              }}
                            ></div>
                          </div>

                          <img className="img" src={item.url} />
                          <div className="data">
                            <div className="d-flex top align-items-start">
                              <div className="dage">
                                {tipMap[item.nft_sale_type]}
                              </div>
                              <div className="title flex-fill">
                                {item.nft_name}
                              </div>
                            </div>
                            <div className="line"></div>
                            <div className="info">{item.contract_info}</div>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              )}
              {tab == 1 && (
                <div className="cs-tab active">
                  {getList1((list) => (
                    <>
                      {list.map((item, idx) => (
                        <div
                          className="order d-md-flex"
                          key={item.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/nft-application-form?id=${item.id}&check=1`
                            );
                          }}
                        >
                          <div className="d-flex flex-column justify-content-between align-items-start me-4">
                            <div className="time">
                              <div className="year">
                                {dayjs(item.create_time).format("YYYY")}
                              </div>
                              <TimeText create_time={item.create_time} />
                            </div>
                            <div
                              className="bg-text fs-4 d-inline-block"
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  "application.approval_complete_duplicate"
                                ),
                              }}
                            ></div>
                          </div>
                          <img className="img" src={item.url} />
                          <div className="data">
                            <div className="d-flex top align-items-start">
                              <div className="dage">
                                {tipMap[item.nft_sale_type]}
                              </div>
                              <div className="title flex-fill">
                                {item.nft_name}
                              </div>
                              {item.mint == 0 && (
                                <div
                                  className="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toMint(item);
                                  }}
                                >
                                  MINT
                                </div>
                              )}
                              {item.mint == 1 && (
                                <div
                                  className="button"
                                  style={{
                                    background:
                                      "linear-gradient(90deg, #242424 36.5%, #3772FF 100%)",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {t("application.mint_in_progress")}
                                </div>
                              )}
                              {item.mint == 3 && (
                                <div
                                  className="button"
                                  style={{ background: "#FF4E4E" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {t("application.mint_rejected")}
                                </div>
                              )}
                              {item.mint == 2 && (
                                <div
                                  className="button"
                                  style={{ background: "#494F6A" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {t("application.mint_complete")}
                                </div>
                              )}
                            </div>
                            <div className="line"></div>
                            <div className="info">{item.contract_info}</div>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              )}
              {tab == 2 && (
                <div className="cs-tab active">
                  {getList2((list) => (
                    <>
                      {list.map((item, idx) => (
                        <div
                          className="d-md-flex order2"
                          key={item.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/nft-application-form?id=${item.id}`);
                          }}
                        >
                          <div className="d-flex flex-column justify-content-between align-items-start me-4">
                            <div className="time">
                              <div className="year">
                                {dayjs(item.create_time).format("YYYY")}
                              </div>
                              <TimeText create_time={item.create_time} />
                            </div>
                            <div
                              className="bg-text fs-4 d-inline-block"
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  "application.approval_rejected_duplicate"
                                ),
                              }}
                            ></div>
                          </div>
                          <img className="img" src={item.url} />
                          <div className="data">
                            <div className="d-flex top align-items-start">
                              <div className="dage">
                                {tipMap[item.nft_sale_type]}
                              </div>
                              <div className="title flex-fill">
                                {item.nft_name}
                              </div>
                              {/* <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.75 24.75L20.25 20.25H9C8.38125 20.25 7.85156 20.0297 7.41094 19.5891C6.97031 19.1484 6.75 18.6187 6.75 18V16.875H19.125C19.7437 16.875 20.2734 16.6547 20.7141 16.2141C21.1547 15.7734 21.375 15.2437 21.375 14.625V6.75H22.5C23.1187 6.75 23.6484 6.97031 24.0891 7.41094C24.5297 7.85156 24.75 8.38125 24.75 9V24.75ZM4.5 13.6969L5.82187 12.375H16.875V4.5H4.5V13.6969ZM2.25 19.125V4.5C2.25 3.88125 2.47031 3.35156 2.91094 2.91094C3.35156 2.47031 3.88125 2.25 4.5 2.25H16.875C17.4937 2.25 18.0234 2.47031 18.4641 2.91094C18.9047 3.35156 19.125 3.88125 19.125 4.5V12.375C19.125 12.9937 18.9047 13.5234 18.4641 13.9641C18.0234 14.4047 17.4937 14.625 16.875 14.625H6.75L2.25 19.125Z" fill="url(#paint0_linear_3089_22309)" />
                        <defs>
                          <linearGradient id="paint0_linear_3089_22309" x1="2.25" y1="13.5" x2="24.75" y2="13.5" gradientUnits="userSpaceOnUse">
                            <stop offset="0.365" stopColor="#242424" />
                            <stop offset="1" stopColor="#3772FF" />
                          </linearGradient>
                        </defs>
                      </svg> */}
                              &nbsp;
                              <div
                                className="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(
                                    `/nft-application-form?id=${item.id}`
                                  );
                                }}
                              >
                                {t("application.edit")}
                              </div>
                            </div>
                            <div className="line"></div>
                            <div className="info">{item.contract_info}</div>
                            <div className="d-flex justify-content-between align-items-center option">
                              <div className="red">{item.fail}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                  {/* <PagingBtn /> */}
                </div>
              )}
              {tab == 3 && (
                <div className="cs-tab active">
                  {getList3((list) => (
                    <>
                      {list.map((item, idx) => (
                        <div
                          className="d-md-flex order2"
                          key={item.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/nft-application-form?id=${item.id}`);
                          }}
                        >
                          <div className="d-flex flex-column justify-content-between align-items-start me-4">
                            <div className="time">
                              <div className="year">
                                {dayjs(item.create_time).format("YYYY")}
                              </div>
                              <TimeText create_time={item.create_time} />
                            </div>
                            <div
                              className="bg-text fs-4 d-inline-block"
                              dangerouslySetInnerHTML={{
                                __html: t("application.temp_save"),
                              }}
                            ></div>
                          </div>
                          <img className="img" src={item.url} />
                          <div className="data">
                            <div className="d-flex top align-items-start">
                              <div className="dage">
                                {tipMap[item.nft_sale_type]}
                              </div>
                              <div className="title flex-fill">
                                {item.nft_name}
                              </div>
                              <div
                                className="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(
                                    `/nft-application-form?id=${item.id}`
                                  );
                                }}
                              >
                                {t("application.edit_duplicate")}
                              </div>
                              &nbsp;
                              <div
                                className="button"
                                style={{ background: "#2B2B2B" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  submit(item);
                                }}
                              >
                                {t("application.submit_duplicate")}
                              </div>
                            </div>
                            <div className="line"></div>
                            <div className="info">{item.contract_info}</div>
                            <div className="d-flex justify-content-between align-items-center option">
                              <div className="red">{item.fail}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                  {/* <PagingBtn /> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </MineLayout>
    </>
  );
});
