import { message, Modal } from "antd";
import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { useWallet } from "../WalletContext";
const ConfirmModal = forwardRef((props, ref) => {
  const [modal, contextModalHolder] = Modal.useModal();
  const { t } = useTranslation();
  const { wallet, connectWallet, disconnectWallet, clearAppState } =
    useWallet();
  useImperativeHandle(ref, () => ({
    openConfirm: () => {
      confirm();
    },
  }));

  let confirm = () => {
    let mint = modal.confirm({
      className: "mint-modal",
      title: null,
      width: "650px",
      content: (
        <div className="box">
          <h3
            className="title text-center GmarketSans"
            style={{ fontSize: "30px", fontWeight: 700, color: "#2B2B2B" }}
          >
            {t("home.wallet_connection")}
          </h3>
          <div
            className="text-center mx-auto"
            style={{
              maxWidth: "560px",
              height: "84px",
              fontSize: "20px",
              marginBottom: "25px",
              marginTop: "40px",
              fontWeight: 300,
              background: "#F6F6F6",
              lineHeight: "84px",
              fontWeight: 500,
              color: "#2B2B2B",
              borderRadius: "20px",
            }}
          >
            {t("components.wallerDesc")}
          </div>
        </div>
      ),
      footer: (
        <div
          className="d-flex gap-3 footer-btn-group mx-auto"
          style={{ maxWidth: "100%" }}
        >
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 hollow"
            onClick={() => {
              mint.destroy();
            }}
          >
            <span>{t("application.cancel")}</span>
          </button>
          <button
            className="cs-btn cs-style1 cs-btn_lg flex-1 w-100"
            onClick={() => {
              mint.destroy();
              connectWallet();
            }}
          >
            <span>{t("application.next")}</span>
          </button>
        </div>
      ),
    });
  };

  return <>{contextModalHolder}</>;
});

export default ConfirmModal;
