import { Carousel } from "antd";
import BCheck from "@/components/b-check"
import { useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
export default forwardRef(({ list = [], checkFile = () => { }, upload = () => { }, updateForm = () => (() => { }), formData = { nftUrls: [] } }, ref) => {
  const { t } = useTranslation();
  let currentList = () => {
    let _list = [];
    for (let i = 0; i < Math.abs(list.length / 9); i++) {
      let items = [];
      for (let j = 0; j < 9; j++) {
        items.push(list[i * 9 + j] || undefined);
      }
      _list.push(items);
    }
    if (!list.length) return [[]];
    return _list;
  };
  let [checkeds, setCheckeds] = useState([])
  let checked = (idx) => {
    if (checkeds.includes(idx)) {
      checkeds.splice(checkeds.findIndex((item => item == idx)), 1)
    } else {
      checkeds.push(idx)
    }
    setCheckeds([...checkeds])
  }
  useImperativeHandle(ref, () => ({
    delete: () => {
      checkeds.forEach((item) => {
        formData.nftUrls[item] = undefined
        if (formData.showIndex == item) {
          formData.showIndex = [0]
        }
      })
      setCheckeds([])
      updateForm(() => {
        formData.nftUrls = formData.nftUrls.filter(item => item);
      })()
    }
  }))
  return (
    <div className="imgs-list">
      <div className="upload-box">
        <div className="tip">{t('application.upload_image_file_here')}</div>
        <input type="file" accept=".jpg,.png" multiple onChange={upload} />
        <button className="btn">
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.95817 10.2904H7.0415V8.02891L7.90817 8.89557L8.6665 8.1237L6.49984 5.95703L4.33317 8.1237L5.10505 8.88203L5.95817 8.02891V10.2904ZM3.24984 11.9154C2.95192 11.9154 2.69689 11.8093 2.48473 11.5971C2.27258 11.385 2.1665 11.1299 2.1665 10.832V2.16536C2.1665 1.86745 2.27258 1.61241 2.48473 1.40026C2.69689 1.18811 2.95192 1.08203 3.24984 1.08203H7.58317L10.8332 4.33203V10.832C10.8332 11.1299 10.7271 11.385 10.5149 11.5971C10.3028 11.8093 10.0478 11.9154 9.74984 11.9154H3.24984ZM7.0415 4.8737V2.16536H3.24984V10.832H9.74984V4.8737H7.0415Z"
              fill="white"
            />
          </svg>
          &nbsp; {t('application.file_upload')}
        </button>
      </div>
      <Carousel style={{ margin: "0 auto" }} arrows={true} infinite={false}>
        {currentList().map((parent, idx) => (
          <div key={idx}>
            <div className="imgs">
              {Array(9)
                .fill("")
                .map((item, index) => (
                  <div
                    className={"item " + (parent[index] && "has")}
                    key={index}
                    onClick={
                      parent[index]
                        ? updateForm(() => formData.showIndex = (index + idx * 9))
                        : () => checkFile(idx * 9 + index)
                    }
                  >
                    {parent[index] && <BCheck className="check" checked={checkeds.includes(idx * 9 + index)}
                      onClick={(e) => {
                        e.stopPropagation()
                        checked(idx * 9 + index)
                      }} />}
                    {parent[index] && <img src={parent[index]} />}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
});
